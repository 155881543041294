import React, { useContext, useEffect } from "react";

import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  styled,
  Table,
  TableContainer,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AppCheckBox from "components/AppCheckBox";
import Scrollbar from "components/ScrollBar";
import { H5, Tiny } from "components/Typography";
import { useState } from "react";
import TableHeader from "page-sections/dashboards/CRM/customerList/TableHeader";
import { championshipsStore } from "store/championships/championshipStore";
import { BeatLoader } from "react-spinners";
import { useLocation, useNavigate } from "react-router-dom";
// import { AuthContext } from "contexts/JWTAuth";
import { classStore } from "store/class/classStore";
import KelasDelete from "./kelas-delete";
import KelasMassDelete from "./kelas-mass-delete";
import FlexBetween from "components/flexbox/FlexBetween";
import useAuth from "hooks/useAuth";
import { StyledPagination } from "components/AppPagination";
import { latihanStore } from "store/latihan/latihanStore";
import { latihanDaftarPesertaStore } from "store/latihanDaftarPeserta/latihanDaftarPesertaStore";
// import KejuaraanDelete from "./kejuaraan-delete";
// import KejuaraanMassDelete from "./kejuaraan-mass-delete";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if (!stabilizedThis) {
    return [];
  }
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableHeading = [
  {
    id: "token",
    label: "Nomor Token",
    alignRight: false,
  },
  {
    id: "name",
    label: "Nama Pembalap",
    alignRight: false,
  },
  {
    id: "team_name",
    label: "Nama Tim",
    alignRight: false,
  },
  {
    id: "date",
    label: "Tanggal Latihan",
    alignRight: false,
  },
  {
    id: "price_class.name",
    label: "Nama Kelas",
    alignRight: false,
  },
  {
    id: "status",
    label: "Status",
    alignRight: false,
  },
  {
    id: "action",
    label: "Action",
    alignRight: false,
  },
];

const StyledTableCell = styled(TableCell)(() => ({
  paddingTop: 10,
  paddingBottom: 10,
}));

const LatihanDaftarPesertaList = () => {
  const [page, setPage] = useState(0);
  const [pagePagination, setPagePagination] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const location = useLocation();

  const { accessToken } = useAuth();
  const {
    fetchLatihanDaftarPeserta,
    latihanDaftarPesertaData,
    loading,
    latihanDaftarPesertaTotalData,
  } = latihanDaftarPesertaStore();
  const {
    classData,
    createClass,
    massInactiveClassData,
    fetchClassData,
    classTotalData,
  } = classStore();
  const navigate = useNavigate();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = latihanDaftarPesertaData.map((n) => n.form_code);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  useEffect(() => {
    let payload = {
      page: pagePagination + 1,
      limit: latihanDaftarPesertaTotalData,
    };
    fetchLatihanDaftarPeserta(payload, accessToken);
  }, [pagePagination]);

  const handleDeleteSelected = () => {
    if (selected.length > 0) {
      setOpenModal(true);
    }
  };

  const handleMassInactive = () => {
    massInactiveClassData(selected, accessToken);
  };

  const handleCopyData = (name) => {
    const dataToCopy = classData.find((row) => row.name === name);
    createClass(dataToCopy);
  };

  const handleClick = (token) => {
    const selectedIndex = selected.indexOf(token);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, token);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedId(token);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPageValue = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPageValue);
    setPage(0);
  };

  const handleChange = (_, currentPageNo) => {
    setPagePagination(currentPageNo - 1);
  };

  const filteredUsers = stableSort(
    latihanDaftarPesertaData,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <H5 mb={2}>Daftar Peserta</H5>

      <Scrollbar>
        <TableContainer
          sx={{
            minWidth: 800,
          }}
        >
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              heading={tableHeading}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowCount={latihanDaftarPesertaData?.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {loading ? (
                <StyledTableCell colSpan={6} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100px",
                    }}
                  >
                    <BeatLoader color="#2499EF" />
                  </div>
                </StyledTableCell>
              ) : (
                filteredUsers?.map((row, index) => {
                  const { token, name, team_name, date, status, price_class } =
                    row;
                  const isItemSelected = selected.indexOf(token) !== -1;
                  return (
                    <TableRow
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <StyledTableCell padding="checkbox">
                        <AppCheckBox
                          checked={isItemSelected}
                          onClick={() => handleClick(token)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Tiny fontWeight={600} color="text.primary">
                            {token}
                          </Tiny>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Tiny fontWeight={600} color="text.primary">
                            {name}
                          </Tiny>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {team_name}
                      </StyledTableCell>
                      <StyledTableCell align="left">{date}</StyledTableCell>
                      <StyledTableCell align="left">
                        {price_class.name}
                      </StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{
                          color:
                            status === "approved"
                              ? "success.main"
                              : "error.main",
                        }}
                      >
                        {status}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {/* <IconButton>
                          <Visibility
                            sx={{ color: "text.disabled" }}
                            onClick={() => {
                              navigate("/latihan/daftar-peserta-detail", {
                                state: { detailKejuaraan: row },
                              });
                            }}
                          />
                        </IconButton> */}
                        {/* <IconButton>
                          <Edit
                            sx={{ color: "text.disabled" }}
                            onClick={() => {
                              navigate("/latihan/daftar-peserta-edit", {
                                state: { detailKejuaraan: row },
                              });
                            }}
                          />
                        </IconButton> */}
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Stack alignItems="center" marginY="1rem">
        <StyledPagination
          shape="rounded"
          onChange={handleChange}
          count={Math.min(10, latihanDaftarPesertaTotalData)}
        />
      </Stack>
    </Card>
  );
};

export default LatihanDaftarPesertaList;
