import React, { useState } from "react";
import { Box, styled, TextField } from "@mui/material";
import { DatePicker } from "antd";
import { H6 } from "components/Typography";

const DatePickerRange = ({ selectedDateRange, handleDateChange }) => {
  return (
    <Box>
      <H6 padding={0.2}>Tanggal</H6>
      <DatePicker.RangePicker
        value={selectedDateRange}
        onChange={handleDateChange}
      />
    </Box>
  );
};

export default DatePickerRange;
