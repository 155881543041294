import { Button, Stack, styled, Card, Divider } from "@mui/material";
import AppModal from "components/AppModal";
import { useContext, useState } from "react"; // custom styled components
import { Box } from "@mui/system";
import AppCheckBox from "components/AppCheckBox";
import { H5, H6, Tiny } from "components/Typography";
import { championshipsStore } from "store/championships/championshipStore";
// import { AuthContext } from "contexts/JWTAuth";
import { useNavigate } from "react-router-dom";
import { classStore } from "store/class/classStore";
import useAuth from "hooks/useAuth";
import { roleStore } from "store/role/roleStore";

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: 300,
  },
}));

const AccessManagementDelete = ({ open, setOpen, selectedId }) => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const { deleteRoleData } = roleStore();

  const handleDelete = () => {
    deleteRoleData(selectedId, accessToken);
    setOpen(false);
    navigate("/management/access-management");
  };

  return (
    <StyledAppModal open={open} handleClose={() => setOpen(false)}>
      <Card sx={{ pb: 3 }}>
        <Box padding={3}>
          <H5 mb={1}>Hapus Data Role ID {selectedId}</H5>
          <Tiny lineHeight={1.7} maxWidth={600}>
            Apakah kamu yakin ingin menghapus data ini?
          </Tiny>
        </Box>

        <Divider />

        <Stack px={3} direction="row" spacing={2}>
          <Button variant="contained" color="error" onClick={handleDelete}>
            Delete
          </Button>
          <Button variant="outlined" onClick={() => setOpen(false)}>
            Cancel
          </Button>
        </Stack>
      </Card>
    </StyledAppModal>
  );
};

export default AccessManagementDelete;
