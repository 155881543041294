import {
  IconButton,
  Box,
  styled,
  LinearProgress,
  CircularProgress,
  Typography,
} from "@mui/material";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import { Small } from "components/Typography";
import ImageUploadIcon from "icons/ImageUploadIcon";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { fileUploadStore } from "store/fileUpload/fileUploadStore";
import useAuth from "hooks/useAuth";
import PropTypes from "prop-types";

const PreviewImage = styled("img")`
  max-width: 70%;
  max-height: 50%;
  border-radius: 8px;
`;

const ImageUploadContainer = styled("div")`
  height: 150px;
`;

const ImagePreviewContainer = styled(Box)`
  width: 100%;
  height: 50%;
  position: relative;
`;

const ImageUploadInput = ({
  handleOnChange,
  id,
  initialImage,
  resetImage,
  showPreview,
  loading,
}) => {
  const [previewImage, setPreviewImage] = useState(initialImage || null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setPreviewImage(URL.createObjectURL(file));
    }
    handleOnChange(event);
  };

  const handleDeleteImage = () => {
    setPreviewImage(null);
  };

  return (
    <label htmlFor={`icon-button-file-${id}`}>
      <div>
        <input
          type="file"
          accept="image/*"
          id={`icon-button-file-${id}`}
          style={{ display: "none" }}
          onChange={(event) => {
            setIsLoading(true);
            handleFileChange(event);
            setIsLoading(false);
          }}
          value="" // Reset the value to prevent default browser styling
        />
      </div>
      <IconButton
        disableRipple
        component="span"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "1px solid #ccc",
          borderRadius: "8px",
          padding: "8px",
          cursor: "pointer",
          backgroundColor: "#f7f7f7",
          transition: "background-color 0.3s ease-in-out",
          "&:hover": {
            backgroundColor: "#e0e0e0",
          },
        }}
        aria-label={
          showPreview && previewImage ? "Delete Image" : "Upload Image"
        }
      >
        <FlexRowAlign
          gap={0.5}
          sx={{
            minHeight: 40,
            borderRadius: "8px",
          }}
        >
          {loading ? (
            <Box mt={2}>
              <CircularProgress size={24} />
            </Box>
          ) : showPreview && previewImage ? (
            <ImagePreviewContainer>
              <PreviewImage src={previewImage} alt="Preview" />
              <IconButton
                size="small"
                aria-label="Delete"
                onClick={handleDeleteImage}
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  color: "white",
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                }}
              >
                <DeleteIcon />
              </IconButton>
            </ImagePreviewContainer>
          ) : (
            <>
              <ImageUploadIcon sx={{ width: 43, height: 24, color: "gray" }} />
              <Small color="text.disabled">Choose a file</Small>
            </>
          )}
        </FlexRowAlign>
      </IconButton>
    </label>
  );
};

export default ImageUploadInput;
