import { Visibility } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const columnShape = [
  {
    Header: "Nomor Form",
    accessor: "form_code",
  },
  {
    Header: "Tim",
    accessor: "team_name",
  },
  {
    Header: "Nama Pembalap",
    accessor: "name",
  },
  {
    Header: "Kelas",
    accessor: "price_class.name",
  },
  {
    Header: "Merek Kendaraan",
    accessor: "vehicle.merk",
  },
  {
    Header: "Kapasitas Mesin",
    accessor: "vehicle.machine_capacity",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => {
      const statusColor =
        value === "approved"
          ? "#1E88E5" // Blue
          : value === "rejected"
          ? "#E53935" // Red
          : value === "request"
          ? "#43A047"
          : value === "expired"
          ? "#FFB400"
          : "transparent"; // Green
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: `${statusColor}`,
            borderRadius: "8px",
            color: "white",
            padding: "6px",
          }}
        >
          {value}
        </div>
      );
    },
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: (props) => {
      const navigate = useNavigate();
      const { id } = props.row.original;
      return (
        <IconButton
          onClick={() => {
            navigate(`/latihan/verifikasi-detail/${id}`);
          }}
        >
          <Visibility
            sx={{
              color: "text.disabled",
              fontSize: 18,
            }}
          />
        </IconButton>
      );
    },
  },
];

export default columnShape;
