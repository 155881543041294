import { BASE_URL_ENDPOINT } from "constant/constanta";
import { create } from "zustand";
import axios from "axios";
import { toastStore } from "store/toast/toastStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const roleStore = create((set) => ({
  roleData: [],
  roleDetailData: [],
  permissionsData: [],
  loading: false,
  error: null,

  fetchRoleData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(`${BASE_URL_ENDPOINT}/api/roles`, {
        params: { ...payload },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = response.data.data;
      set({ roleData: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  fetchRoleDetailData: async (id, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(`${BASE_URL_ENDPOINT}/api/role/${id}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = response.data.data;
      set({ roleDetailData: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  fetchPermissions: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(`${BASE_URL_ENDPOINT}/api/permissions`, {
        params: { ...payload },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = response.data.data;
      set({ permissionsData: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  createRole: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/role/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ roleData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  editRoleData: async (id, payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/role/update/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ classData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  massInactiveClassData: async (ids, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/class/set-mass-inactive`,
        {
          class_ids: ids,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ classData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  deleteRoleData: async (id, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/role/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          roleData: state.classData.filter((dataClass) => dataClass.id !== id),
          loading: false,
        }));
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  massDeleteRoleData: async (ids, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/class/mass-delete`,
        {
          data: {
            class_ids: ids,
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          classData: state.classData.filter(
            (dataClass) => !ids.includes(dataClass.id)
          ),
          loading: false,
        }));
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  downloadClassData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/class/download`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          responseType: "blob",
        }
      );
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "Data Kelas.xlsx"; // Default file name
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="([^"]+)"/);
        if (match) {
          fileName = match[1];
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
      set({ loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },
}));
