import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  styled,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import FlexBetween from "components/flexbox/FlexBetween";
import { H3, H5, H6, Small, Span, Tiny } from "components/Typography";
import DownloadTo from "icons/DownloadTo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { lightTheme } from "../../constants"; // styled components
import { inputFormStore } from "store/inputForm/inputFormStore";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import { useFormik } from "formik";
import { fileUploadStore } from "store/fileUpload/fileUploadStore";
import AppTextField from "components/input-fields/AppTextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { KeyboardArrowDown } from "@mui/icons-material";
import { latihanStore } from "store/latihan/latihanStore";
import { inputFormLatihanStore } from "store/inputFormLatihan/inputFormLatihanStore";
import axios from "axios";
import * as Yup from "yup";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import LoadingScreen from "components/LoadingScreen";
import ImageCompressor from "image-compressor.js";
import CircularProgress from "@mui/material/CircularProgress";
// import TableDataKonfirmasi from "./table-data-konfirmasi";

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  paddingBottom: 5,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${
    lightTheme(theme) ? theme.palette.grey[300] : theme.palette.divider
  }`,
  "&:last-of-type": {
    textAlign: "right",
  },
}));
const BodyTableCell = styled(TableCell)(() => ({
  fontSize: 12,
  padding: "10px 0",
  "&:last-of-type": {
    textAlign: "right",
    fontWeight: 600,
  },
}));
const StyledSmall = styled(Small)(({ theme, type }) => ({
  fontSize: 12,
  color: "white",
  padding: "4px 10px",
  borderRadius: "4px",
  backgroundColor:
    type === "success"
      ? theme.palette.success.main
      : theme.palette.primary.main,
}));

const InputFormDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { accessToken } = useAuth();
  const { payload } = location.state;
  const { createLatihanInputForm } = inputFormLatihanStore();
  const { setFileUploadData, fileUploadData } = fileUploadStore();
  const {
    fetchLatihanData,
    latihanData,
    fetchLatihanDetailData,
    latihanDetailData,
    loading,
  } = latihanStore();
  const [selectedFile, setSelectedFile] = useState(null);
  const [showImagePreview, setShowImagePreview] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [imageLoadingKis, setImageLoadingKis] = useState(false);

  useEffect(() => {
    let payloadLatihan = {
      status: "active",
    };
    fetchLatihanData(payloadLatihan);
    fetchLatihanDetailData(payload.training_class_id);
  }, [fetchLatihanData]);

  const initialValues = {
    training_class_id: payload.training_class_id,
    name: payload.name,
    email: payload.email,
    date_of_birth: payload.date_of_birth
      ? new Date(payload.date_of_birth)
      : new Date(),
    date: payload.date ? new Date(payload.date) : new Date(),
    place_of_birth: payload.place_of_birth,
    team_name: payload.team_name,
    emergency_contact: payload.emergency_contact,
    vehicle_merk: payload.vehicle_merk,
    vehicle_livery: payload.vehicle_livery,
    vehicle_machine_capacity: payload.vehicle_machine_capacity,
    payment_url_file: "",
    price: payload.price,
    telp: payload.telp,
    address: payload.address,
    kis: payload.kis,
    pengprove: payload.pengprove,
    price: payload.price,
  };

  const validationSchema = Yup.object().shape({
    payment_url_file: Yup.mixed()
      .nullable()
      .required("Wajib Mengupload Bukti Bayar"),
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let payload = {
        training_class_id: values?.training_class_id,
        name: values?.name,
        email: values?.email,
        date_of_birth: values?.date_of_birth,
        place_of_birth: values?.place_of_birth,
        address: values?.address,
        date: values?.date,
        kis: values?.kis,
        team_name: values?.team_name,
        pengprove: values?.pengprove,
        emergency_contact: values?.emergency_contact,
        telp: values?.telp,
        payment_url_file: values?.payment_url_file,
        vehicle_machine_capacity: values?.vehicle_machine_capacity,
        vehicle_livery: values?.vehicle_livery,
        vehicle_merk: values?.vehicle_merk,
        price: values.price,
      };
      createLatihanInputForm(payload, navigate);
    },
  });

  const handleUploadFile = async (fieldName, e) => {
    const file = e.target.files[0];
    try {
      const compressedFile = await new ImageCompressor().compress(file, {
        quality: 0.6,
      });
      const formData = new FormData();
      formData.append("file", compressedFile);
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/file/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.data.code === 201) {
        console.log(
          `File uploaded successfully for ${fieldName}:`,
          response.data
        );
        setFieldValue(fieldName, response.data?.data?.file_url);
        setSelectedFile(response.data?.file_url);
      }
    } catch (error) {
      console.error(`Error uploading file for ${fieldName}:`, error);
    }
  };

  const PriceFormatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Box pt={2} pb={4}>
          <Card
            sx={{
              padding: "2rem 1rem",
            }}
          >
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <form onSubmit={handleSubmit}>
                  <FlexBetween>
                    <Box>
                      <H3>Konfirmasi Input Form</H3>
                    </Box>

                    <Stack
                      direction="row"
                      justifyContent="flex-end"
                      spacing={2}
                    >
                      <Button
                        variant="outlined"
                        onClick={() => {
                          navigate("/latihan/input-form", {
                            state: { payloadKembali: payload },
                          });
                        }}
                      >
                        Kembali
                      </Button>
                      <Button variant="contained" type="submit" disabled={loading}>
                        Submit
                      </Button>
                    </Stack>
                  </FlexBetween>
                  <Divider sx={{ marginTop: "16px" }} />
                  <Stack spacing={1}>
                    <H5 mt={2}>Upload Bukti Bayar</H5>
                    <ImageUploadInput
                      name="payment_url_file"
                      id="payment_url_file"
                      handleOnChange={async (e) => {
                        setImageLoading(true);
                        await handleUploadFile("payment_url_file", e);
                        setShowImagePreview(true);
                        setImageLoading(false);
                      }}
                      showPreview={
                        showImagePreview && values?.payment_url_file !== null
                      }
                    />
                    {imageLoading ? (
                      <Box
                        sx={{
                          marginTop: 2,
                          display: "flex",
                          justifyContent: "center", // Center horizontally
                          alignItems: "center", // Center vertically (optional)
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    ) : (
                      touched.payment_url_file &&
                      errors.payment_url_file && (
                        <StyledSmall type="error">
                          {errors.payment_url_file}
                        </StyledSmall>
                      )
                    )}
                  </Stack>

                  <H3 mt={2}>Rincian Harga</H3>

                  <FlexBetween my={3}>
                    <Stack spacing={0.5}>
                      <H5>Tanggal Latihan</H5>
                      <Tiny fontWeight={500} lineHeight={1.6}>
                        {new Date(payload?.date).toLocaleDateString("id-ID", {
                          weekday: "long",
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          timeZone: "Asia/Jakarta",
                        })}
                      </Tiny>
                    </Stack>

                    <Stack spacing={0.5}>
                      <H5>Merk Kendaraan</H5>
                      <Tiny fontWeight={500} lineHeight={1.6}>
                        {payload?.vehicle_merk}
                      </Tiny>
                    </Stack>

                    <Stack spacing={0.5}>
                      <H5>Harga</H5>
                      <Tiny fontWeight={500} lineHeight={1.6}>
                        {PriceFormatter.format(payload?.price)}
                      </Tiny>
                    </Stack>

                    <Stack spacing={0.5}>
                      <H5>Total</H5>
                      <Tiny fontWeight={500} lineHeight={1.6}>
                        {PriceFormatter.format(payload?.price)}
                      </Tiny>
                    </Stack>
                  </FlexBetween>
                  <H5 padding={3}>Data Pembalap</H5>
                  <Divider />
                  <Box margin={3}>
                    <Grid container spacing={3}>
                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="name"
                          label="Nama Pembalap (Sesuai KTP)"
                          variant="outlined"
                          value={values?.name}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          fullWidth
                          disabled
                          name="address"
                          label="Alamat (Sesuai KTP)"
                          variant="outlined"
                          value={values?.address}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <DatePicker
                          disabled
                          label="Tanggal Lahir"
                          name="date_of_birth"
                          value={values?.date_of_birth}
                          onChange={(newValue) =>
                            setFieldValue("date_of_birth", newValue)
                          }
                          slots={{
                            textField: AppTextField,
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="place_of_birth"
                          label="Tempat Lahir"
                          variant="outlined"
                          value={values?.place_of_birth}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="kis"
                          label="No. Kartu Izin Start (KIS)"
                          variant="outlined"
                          value={values?.kis}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="telp"
                          label="Nomor Hp"
                          type="number"
                          variant="outlined"
                          value={values?.telp}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="pengprove"
                          label="Pengprove. IMI / ASN"
                          variant="outlined"
                          value={values?.pengprove}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="team_name"
                          label="Nama Team"
                          variant="outlined"
                          value={values?.team_name}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="emergency_contact"
                          label="Kontak Darurat"
                          type="number"
                          variant="outlined"
                          value={values?.emergency_contact}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="email"
                          label="Email Pembalap"
                          variant="outlined"
                          value={values?.email}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <DatePicker
                          disabled
                          label="Tanggal Latihan"
                          value={values?.date}
                          name="date"
                          onChange={(newValue) =>
                            setFieldValue("date", newValue)
                          }
                          slots={{
                            textField: AppTextField,
                          }}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                            },
                          }}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          select
                          fullWidth
                          label="Jenis Kendaraan / Kelas Latihan"
                          variant="outlined"
                          placeholder="Pilih Jenis Kendaraan / Jenis Latihan"
                          name="training_class_id"
                          value={values?.training_class_id || ""}
                          onChange={(event) => {
                            const selectedOption = latihanData.find(
                              (option) => option.id === event.target.value
                            );
                            setFieldValue(
                              "training_class_id",
                              event.target.value
                            );
                            setFieldValue(
                              "price",
                              selectedOption?.price || null
                            ); // Set the price from the selected option
                          }}
                          onBlur={handleBlur}
                          SelectProps={{
                            native: true,
                            IconComponent: KeyboardArrowDown,
                          }}
                          helperText={
                            touched?.training_class_id &&
                            errors?.training_class_id
                          }
                          error={Boolean(
                            touched?.training_class_id &&
                              errors?.training_class_id
                          )}
                        >
                          <option value="" disabled></option>
                          {latihanData?.map((option) => (
                            <option key={option.id} value={option.id}>
                              {option.name}
                            </option>
                          ))}
                        </AppTextField>
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="vehicle_merk"
                          label="Merek & Tipe Kendaraan"
                          variant="outlined"
                          value={values?.vehicle_merk}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="vehicle_livery"
                          label="Warna / Livery"
                          variant="outlined"
                          value={values?.vehicle_livery}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <AppTextField
                          disabled
                          fullWidth
                          name="vehicle_machine_capacity"
                          label="Kapasitas Mesin"
                          variant="outlined"
                          value={values?.vehicle_machine_capacity}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Divider />
                    </Grid>
                  </Box>
                </form>
              </Grid>
            </Grid>
          </Card>
        </Box>
      )}
    </>
  );
};

export default InputFormDetail;
