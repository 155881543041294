import { Button, Stack, styled, Card, Divider, Grid } from "@mui/material";
import AppModal from "components/AppModal";
import { useContext, useState } from "react"; // custom styled components
import { Box } from "@mui/system";
import AppCheckBox from "components/AppCheckBox";
import { H5, H6, Tiny } from "components/Typography";
import { championshipsStore } from "store/championships/championshipStore";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { classStore } from "store/class/classStore";
import useAuth from "hooks/useAuth";
import AppTextField from "components/input-fields/AppTextField";
import { verifikasiStore } from "store/verifikasi/verifikasiStore";
import { latihanVerifikasiStore } from "store/latihanVerifikasi/latihanVerifikasiStore";

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: 300,
  },
}));

const VerifikasiTerima = ({ open, setOpen, dataID }) => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const { updateStatusEvent } = latihanVerifikasiStore();
  //   const initialValues = {
  //     message: "",
  //   };

  //   const { values, errors, handleSubmit, handleChange, handleBlur, touched } =
  //     useFormik({
  //       initialValues,
  //       onSubmit: (values) => {
  //         updateStatusEvent(dataID, payload, accessToken);
  //         setOpen(false);
  //         navigate("/dashboard/verifikasi");
  //       },
  //     });

  let payload = {
    status: "approved",
  };

  const handleStatusTerima = () => {
    updateStatusEvent(dataID, payload, accessToken, navigate);
    setOpen(false);
  };

  return (
    <StyledAppModal open={open} handleClose={() => setOpen(false)}>
      <Card sx={{ pb: 3 }}>
        <Box padding={3}>
          <H5 mb={1}>Terima Transaksi ini?</H5>
          <Tiny lineHeight={1.7} maxWidth={600}>
            Apakah kamu yakin untuk menyetujui transaksi ini?
          </Tiny>
        </Box>

        <Divider />

        <Stack px={3} direction="row" spacing={2}>
          {/* <form onSubmit={handleSubmit}>
            <AppTextField
              fullWidth
              multiline
              name="message"
              id="message"
              label="Alasan Penolakan"
              variant="outlined"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            /> */}
          <Button
            variant="contained"
            // color="error"
            onClick={handleStatusTerima}
            // sx={{ marginTop: "16px", marginRight: "16px" }}
          >
            Setujui
          </Button>
          <Button
            variant="outlined"
            onClick={() => setOpen(false)}
            // sx={{ marginTop: "16px", marginRight: "16px" }}
          >
            Batal
          </Button>
          {/* </form> */}
        </Stack>
      </Card>
    </StyledAppModal>
  );
};

export default VerifikasiTerima;
