import Icons from "icons/sidebar";
import duotone from "icons/duotone";
const index = [
  {
    type: "label",
    label: "Dashboard",
  },
  {
    name: "Data Master",
    path: "/data-master",
    icon: duotone.RectangleCirclePlus,
    children: [
      {
        name: "Kejuaraan",
        path: "/dashboard/kejuaraan",
        requiredPermissions: ["menu_master_championship"],
      },
      {
        name: "Kelas",
        path: "/dashboard/kelas",
        requiredPermissions: ["menu_master_class_drag"],
      },
      {
        name: "Latihan",
        path: "/dashboard/latihan",
        requiredPermissions: ["menu_master_class_drag"],
      },
      {
        name: "Hari Libur",
        path: "/dashboard/hari-libur",
        requiredPermissions: ["menu_master_holiday"],
      },
    ],
  },
  {
    name: "Event",
    path: "/event",
    icon: duotone.PersonChalkboard,
    children: [
      {
        name: "Input Form",
        path: "/dashboard/input-form",
        requiredPermissions: ["menu_event_form"],
      },
      {
        name: "Verifikasi",
        path: "/dashboard/verifikasi",
        requiredPermissions: ["menu_event_verifications"],
      },
      {
        name: "Daftar Peserta",
        path: "/dashboard/daftar-peserta",
        requiredPermissions: ["menu_event_list_participants"],
      },
    ],
  },
  {
    name: "Latihan",
    path: "/latihan",
    icon: duotone.Calender,
    children: [
      {
        name: "Input Form",
        path: "/dashboard/latihan/input-form",
        requiredPermissions: ["menu_training_form"],
      },
      {
        name: "Verifikasi",
        path: "/dashboard/latihan/verifikasi",
        requiredPermissions: ["menu_training_verifications"],
      },
      {
        name: "Daftar Peserta",
        path: "/dashboard/latihan/daftar-peserta",
        requiredPermissions: ["menu_training_participants"],
      },
    ],
  },
  {
    type: "label",
    label: "Konfigurasi",
  },
  {
    name: "Management",
    path: "/management",
    icon: duotone.Session,
    children: [
      {
        name: "User Management",
        path: "/management/user-management",
        requiredPermissions: ["menu_configuration_admin"],
      },
      {
        name: "Access Management",
        path: "/management/access-management",
        requiredPermissions: ["menu_configuration_admin"],
      },
    ],
  },
];
export default index;
