import React, { useContext, useEffect } from "react";

import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  Table,
  TableContainer,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AppCheckBox from "components/AppCheckBox";
import Scrollbar from "components/ScrollBar";
import { H5, H6, Tiny } from "components/Typography";
import { useState } from "react";
import TableHeader from "page-sections/dashboards/CRM/customerList/TableHeader";
import { championshipsStore } from "store/championships/championshipStore";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import KejuaraanDelete from "./liburan-delete";
import KejuaraanMassDelete from "./liburan-mass-delete";
import useAuth from "hooks/useAuth";
import FlexBetween from "components/flexbox/FlexBetween";
import { StyledPagination } from "components/AppPagination";
import { latihanStore } from "store/latihan/latihanStore";
import LatihanMassDelete from "./liburan-mass-delete";
import { liburanStore } from "store/liburan/liburanStore";
import LiburanMassDelete from "./liburan-mass-delete";
import LiburanDelete from "./liburan-delete";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if (!stabilizedThis) {
    return [];
  }
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableHeading = [
  {
    id: "id",
    label: "ID Libur",
    alignRight: false,
  },
  {
    id: "name",
    label: "Nama Hari Libur",
    alignRight: false,
  },
  {
    id: "date",
    label: "Tanggal",
    alignRight: false,
  },
  {
    id: "status",
    label: "Status",
    alignRight: false,
  },
  {
    id: "action",
    label: "Action",
    alignRight: false,
  },
];

const StyledTableCell = styled(TableCell)(() => ({
  paddingTop: 10,
  paddingBottom: 10,
}));

const LiburanList = () => {
  const [page, setPage] = useState(0);
  const [pagePagination, setPagePagination] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const { accessToken } = useAuth();
  const {
    liburanData,
    loading,
    createChampionships,
    massInactiveChampionshipData,
    fetchLiburanData,
    liburanTotalData,
  } = liburanStore();
  const navigate = useNavigate();

  useEffect(() => {
    let payload = {
      page: pagePagination + 1,
      limit: 5,
    };
    fetchLiburanData(payload, accessToken);
  }, [pagePagination]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = liburanData.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleDeleteSelected = () => {
    if (selected.length > 0) {
      setOpenModal(true);
    }
  };

  const handleChange = (_, currentPageNo) => {
    setPagePagination(currentPageNo - 1);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedId(id);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPageValue = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPageValue);
    setPage(0);
  };

  const filteredUsers = stableSort(liburanData, getComparator(order, orderBy));

  const handleMassInactive = () => {
    massInactiveChampionshipData(selected, accessToken);
  };

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <H5 mb={2}>Daftar Hari Libur</H5>

      <Scrollbar>
        <TableContainer
          sx={{
            minWidth: 800,
          }}
        >
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              heading={tableHeading}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowCount={liburanData?.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {loading ? (
                <StyledTableCell colSpan={6} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100px",
                    }}
                  >
                    <BeatLoader color="#2499EF" />
                  </div>
                </StyledTableCell>
              ) : (
                filteredUsers?.map((row, index) => {
                  const { name, id, status, date } = row;
                  const isItemSelected = selected.indexOf(id) !== -1;
                  return (
                    <TableRow
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <StyledTableCell padding="checkbox">
                        <AppCheckBox
                          checked={isItemSelected}
                          onClick={() => handleClick(id)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Tiny fontWeight={600} color="text.primary">
                            {id}
                          </Tiny>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="left">{name}</StyledTableCell>
                      <StyledTableCell align="left">{date}</StyledTableCell>
                      <StyledTableCell
                        align="left"
                        sx={{
                          color:
                            status === "active" ? "success.main" : "error.main",
                        }}
                      >
                        {status}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <IconButton>
                          <Visibility
                            sx={{ color: "text.disabled" }}
                            onClick={() => {
                              navigate("/dashboard/hari-libur-detail", {
                                state: { detailKejuaraan: row },
                              });
                            }}
                          />
                        </IconButton>
                        <IconButton>
                          <Edit
                            sx={{ color: "text.disabled" }}
                            onClick={() => {
                              navigate("/dashboard/hari-libur-edit", {
                                state: { detailKejuaraan: row },
                              });
                            }}
                          />
                        </IconButton>
                        <IconButton>
                          <Delete
                            sx={{ color: "text.disabled" }}
                            onClick={() => {
                              setOpenModal(true);
                              setSelectedId(id);
                            }}
                          />
                        </IconButton>
                        <LiburanDelete
                          open={openModal}
                          setOpen={setOpenModal}
                          selectedId={selectedId}
                        />
                      </StyledTableCell>
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Stack alignItems="center" marginY="1rem">
        <StyledPagination
          shape="rounded"
          onChange={handleChange}
          count={Math.min(10, liburanTotalData)}
        />
      </Stack>
      {selected.length > 1 && (
        <>
          <Box sx={{ float: "right" }}>
            <FlexBetween>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="error"
                  sx={{
                    ml: 2,
                  }}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Hapus
                </Button>
              </Grid>
            </FlexBetween>
          </Box>
          <LiburanMassDelete
            open={openModal}
            setOpen={setOpenModal}
            selectedId={selected}
          />
        </>
      )}
    </Card>
  );
};

export default LiburanList;
