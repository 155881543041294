import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import FlexBetween from "components/flexbox/FlexBetween";
import { H4, H5, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { classStore } from "store/class/classStore";
import KelasDelete from "./access-management-delete";
import useAuth from "hooks/useAuth";
import { roleStore } from "store/role/roleStore";
import FormControlLabel from "@mui/material/FormControlLabel";
// import KejuaraanDelete from "./kejuaraan-delete";

const StyledFormControl = styled(FormControlLabel)(() => ({
  "& .MuiTypography-root": {
    fontWeight: 600,
    fontSize: 14,
  },
}));

const AccessManagementDetail = () => {
  const { downloadClassData } = classStore();
  const { fetchRoleDetailData, roleDetailData } = roleStore();
  const { accessToken } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const detailKejuaraan = location.state.detailKejuaraan;

  useEffect(() => {
    fetchRoleDetailData(detailKejuaraan?.id, accessToken);
  }, []);

  const createdDate = detailKejuaraan?.created_at
    ? new Date(roleDetailData.created_at)
    : null;
  const createdDateString = createdDate ? createdDate.toLocaleDateString() : "";

  const updatedDate = detailKejuaraan?.udpated_at
    ? new Date(roleDetailData?.udpated_at)
    : null;
  const updatedDateString = createdDate ? updatedDate.toLocaleDateString() : "";

  const theme = useTheme();
  const initialValues = {
    id: roleDetailData?.id,
    name: roleDetailData?.name,
    created_at: createdDateString,
    updated_at: updatedDateString,
  };
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });

  return (
    <>
      <Card
        sx={{
          mt: 3,
        }}
      >
        <H5 padding={3}>Detail Akses</H5>
        <Divider />

        <form onSubmit={handleSubmit}>
          <Box margin={3}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  disabled
                  name="id"
                  label="ID Role"
                  variant="outlined"
                  value={values.id}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  disabled
                  name="name"
                  label="Nama Role"
                  variant="outlined"
                  value={values.name}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  disabled
                  name="created_at"
                  variant="outlined"
                  label="Dibuat Tanggal"
                  value={values.created_at}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  disabled
                  name="updated_at"
                  label="Diubah Tanggal"
                  variant="outlined"
                  value={values?.updated_at}
                />
              </Grid>

              <Divider />
              {roleDetailData?.permissions?.map((item, index) => (
                <Grid item sm={6} xs={12} key={item.id}>
                  <RadioGroup row name={item.name}>
                    <StyledFormControl
                      disabled
                      key={item.id}
                      value={item.id}
                      label={item.name}
                      control={<Radio checked={true} />}
                    />
                  </RadioGroup>
                </Grid>
              ))}
              <Divider />
              <Grid item xs={12}>
                <FlexBetween>
                  <Box>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          navigate("/management/access-management");
                        }}
                      >
                        Kembali
                      </Button>
                      <KelasDelete
                        open={openModal}
                        setOpen={setOpenModal}
                        selectedId={selectedId}
                      />
                    </Grid>
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      onClick={() => {
                        navigate("/management/access-management-edit", {
                          state: { editKejuaraan: detailKejuaraan },
                        });
                      }}
                      sx={{
                        ml: 2,
                      }}
                    >
                      Ubah
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      sx={{
                        ml: 2,
                      }}
                      onClick={() => {
                        setOpenModal(true);
                        setSelectedId(detailKejuaraan?.id);
                      }}
                    >
                      Hapus
                    </Button>
                  </Box>
                </FlexBetween>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default AccessManagementDetail;
