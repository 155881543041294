import { Button, ButtonBase, Divider, Stack, styled, Box } from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { Small } from "components/Typography";
// import Facebook from "icons/Facebook";
// import GoogleIcon from "icons/GoogleIcon";
// import Twitter from "icons/Twitter";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import useAuth from "hooks/useAuth";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthContext from "contexts/JWTAuth";

const StyledButton = styled(ButtonBase)(({ theme }) => ({
  width: "100%",
  padding: 12,
  marginBottom: 16,
  borderRadius: "8px",
  fontWeight: "500",
  border: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.down(454)]: {
    width: "100%",
    marginBottom: 8,
  },
}));

const Login = () => {
  const { login, isAuthenticated, passwordDefault } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState("");
  const [redirectingToReset, setRedirectingToReset] = useState(false);
  let navigate = useNavigate();

  const initialValues = {
    email: localStorage.getItem("email") || "", // Retrieve email from session storage
    password: localStorage.getItem("password") || "",
    submit: null,
    remember: true,
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password should be of minimum 6 characters length")
      .required("Password is required"),
  });

  const {
    errors,
    values,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      login(values.email, values.password)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setError(error.message);
          setLoading(false);
          setLoginError(error.message);
        });
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      setValues((prevValues) => ({
        ...prevValues,
        email: localStorage.getItem("rememberedEmail") || "",
        remember: Boolean(localStorage.getItem("rememberedEmail")),
      }));
      if (passwordDefault) {
        navigate("rubah-password");
      } else {
        navigate("/dashboard");
      }
    }
  }, [isAuthenticated, setValues]);

  return (
    <AuthenticationLayout>
      <form onSubmit={handleSubmit}>
        <Stack gap={2} mt={5}>
          <AppTextField
            fullWidth
            name="email"
            type="email"
            label="Email"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.email || ""}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <AppTextField
            fullWidth
            label="Password"
            type="password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.password || ""}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <FlexBetween>
            <FlexBox alignItems="center" gap={1}>
              <AppCheckBox defaultChecked />
              <Small fontSize={12}>Remember me</Small>
            </FlexBox>
          </FlexBetween>

          {loginError && <Box sx={{ color: "red", mt: 2 }}>{loginError}</Box>}

          <Box
            sx={{
              mt: 4,
            }}
          >
            {loading ? (
              <LoadingButton loading fullWidth variant="contained">
                Sign In
              </LoadingButton>
            ) : (
              <Button fullWidth type="submit" variant="contained">
                Sign In
              </Button>
            )}
          </Box>
        </Stack>
      </form>
    </AuthenticationLayout>
  );
};

export default Login;
