import { BASE_URL_ENDPOINT } from "constant/constanta";
import { create } from "zustand";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const fileUploadStore = create((set) => ({
  fileUploadData: [],
  fileUploadPayment: [],
  loading: false,
  error: null,

  setFileUploadData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/file/upload`,
        payload
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        set({ fileUploadData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  setFileUploadPayment: async (id, payload, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/customer/event/payment/${id}`,
        payload
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        set({ fileUploadData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },
}));
