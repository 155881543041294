import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Stack,
  styled,
  TableRow,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import FlexBetween from "components/flexbox/FlexBetween";
import { H3, H5, H6, Small, Span, Tiny } from "components/Typography";
import DownloadTo from "icons/DownloadTo";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { lightTheme } from "../../constants"; // styled components
import { inputFormStore } from "store/inputForm/inputFormStore";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import { useFormik } from "formik";
import { fileUploadStore } from "store/fileUpload/fileUploadStore";
import AppTextField from "components/input-fields/AppTextField";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { KeyboardArrowDown } from "@mui/icons-material";
import { latihanStore } from "store/latihan/latihanStore";
import { inputFormLatihanStore } from "store/inputFormLatihan/inputFormLatihanStore";
import { latihanVerifikasiStore } from "store/latihanVerifikasi/latihanVerifikasiStore";
import VerifikasiTolak from "pages/verifikasi/verifikasi-tolak";
import VerifikasiTerima from "pages/verifikasi/verifikasi-terima";
// import TableDataKonfirmasi from "./table-data-konfirmasi";

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  padding: 0,
  fontSize: 12,
  fontWeight: 600,
  paddingBottom: 5,
  color: theme.palette.text.secondary,
  borderBottom: `1px solid ${
    lightTheme(theme) ? theme.palette.grey[300] : theme.palette.divider
  }`,
  "&:last-of-type": {
    textAlign: "right",
  },
}));
const BodyTableCell = styled(TableCell)(() => ({
  fontSize: 12,
  padding: "10px 0",
  "&:last-of-type": {
    textAlign: "right",
    fontWeight: 600,
  },
}));
const StyledSmall = styled(Small)(({ theme, type }) => ({
  fontSize: 12,
  color: "white",
  padding: "4px 10px",
  borderRadius: "4px",
  backgroundColor:
    type === "success"
      ? theme.palette.success.main
      : theme.palette.primary.main,
}));

const LatihanVerifikasiDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { accessToken } = useAuth();
  const { createLatihanInputForm } = inputFormLatihanStore();
  const { setFileUploadData, fileUploadData } = fileUploadStore();
  const { fetchLatihanData, latihanData } = latihanStore();
  const [openModal, setOpenModal] = useState(false);
  const [openModalTerima, setOpenModalTerima] = useState(false);
  const {
    fetchLatihanVerifikasiDetailData,
    latihanVerifikasiDetailData,
    sendToken,
  } = latihanVerifikasiStore();

  useEffect(() => {
    fetchLatihanVerifikasiDetailData(id, accessToken);
  }, []);

  useEffect(() => {
    let payload = {
      status: "active",
    };
    fetchLatihanData(payload, accessToken);
  }, [accessToken, fetchLatihanData]);

  const initialValues = {
    training_class_id: latihanVerifikasiDetailData.training_class_id,
    name: latihanVerifikasiDetailData.name,
    email: latihanVerifikasiDetailData.email,
    date_of_birth: latihanVerifikasiDetailData.date_of_birth
      ? new Date(latihanVerifikasiDetailData.date_of_birth)
      : new Date(),
    date: latihanVerifikasiDetailData.date
      ? new Date(latihanVerifikasiDetailData.date)
      : new Date(),
    place_of_birth: latihanVerifikasiDetailData.place_of_birth,
    team_name: latihanVerifikasiDetailData.team_name,
    emergency_contact: latihanVerifikasiDetailData.emergency_contact,
    payment_url_file: latihanVerifikasiDetailData.payment_url_file,
    price: latihanVerifikasiDetailData.price,
    address: latihanVerifikasiDetailData.address,
    kis: latihanVerifikasiDetailData.kis,
    pengprove: latihanVerifikasiDetailData.pengprove,
    price: latihanVerifikasiDetailData.price,
    vehicle: {
      merk: latihanVerifikasiDetailData?.vehicle?.merk,
      livery: latihanVerifikasiDetailData?.vehicle?.livery,
      machine_capacity: latihanVerifikasiDetailData?.vehicle?.machine_capacity,
    },
  };

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });

  const handleSubmitSetujui = () => {
    setOpenModalTerima(true);
  };

  const handleSubmitTolak = () => {
    setOpenModal(true);
  };

  const handleSendToken = () => {
    sendToken(id, accessToken, navigate);
  };

  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          padding: "2rem 1rem",
        }}
      >
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <form onSubmit={handleSubmit}>
              <FlexBetween>
                <Box>
                  <H3>Konfirmasi Verifikasi Latihan</H3>
                </Box>

                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      navigate("/latihan/verifikasi");
                    }}
                  >
                    Kembali
                  </Button>
                  <VerifikasiTolak
                    open={openModal}
                    setOpen={setOpenModal}
                    dataID={id}
                  />
                  <VerifikasiTerima
                    open={openModalTerima}
                    setOpen={setOpenModalTerima}
                    dataID={id}
                  />
                  {latihanVerifikasiDetailData?.status === "request" && (
                    <>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleSubmitTolak}
                      >
                        Tolak
                      </Button>
                      <Button variant="contained" onClick={handleSubmitSetujui}>
                        Setujui
                      </Button>
                    </>
                  )}
                  {latihanVerifikasiDetailData?.status === "approved" && (
                    <Button variant="contained" onClick={handleSendToken}>
                      Send Token (Manual)
                    </Button>
                  )}
                </Stack>
              </FlexBetween>
              <Divider sx={{ marginTop: "16px" }} />

              <FlexBetween>
                <H3 mt={2}>Rincian Harga</H3>
                <Stack spacing={1}>
                  <H5 mt={2}>Bukti Bayar</H5>
                  <Link
                    to={latihanVerifikasiDetailData?.payment?.image_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Bukti Bayar
                  </Link>
                </Stack>
              </FlexBetween>

              <FlexBetween my={3}>
                <Stack spacing={0.5}>
                  <H5>Tanggal Latihan</H5>
                  <Tiny fontWeight={500} lineHeight={1.6}>
                    {new Date(
                      latihanVerifikasiDetailData?.date
                    ).toLocaleDateString("id-ID", {
                      weekday: "long",
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                      timeZone: "Asia/Jakarta",
                    })}
                  </Tiny>
                </Stack>

                <Stack spacing={0.5}>
                  <H5>Merk Kendaraan</H5>
                  <Tiny fontWeight={500} lineHeight={1.6}>
                    {latihanVerifikasiDetailData?.vehicle?.merk}
                  </Tiny>
                </Stack>

                <Stack spacing={0.5}>
                  <H5>Harga</H5>
                  <Tiny fontWeight={500} lineHeight={1.6}>
                    {latihanVerifikasiDetailData?.price}
                  </Tiny>
                </Stack>
                <Stack spacing={0.5}>
                  <H5>Total</H5>
                  <Tiny fontWeight={500} lineHeight={1.6}>
                    {latihanVerifikasiDetailData?.price}
                  </Tiny>
                </Stack>
              </FlexBetween>
              <H5 padding={3}>Data Pembalap</H5>
              <Divider />
              <Box margin={3}>
                <Grid container spacing={3}>
                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="name"
                      label="Nama Pembalap (Sesuai KTP)"
                      variant="outlined"
                      value={values?.name}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      fullWidth
                      disabled
                      name="address"
                      label="Alamat (Sesuai KTP)"
                      variant="outlined"
                      value={values?.address}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <DatePicker
                      disabled
                      label="Tanggal Lahir"
                      name="date_of_birth"
                      value={values?.date_of_birth}
                      onChange={(newValue) =>
                        setFieldValue("date_of_birth", newValue)
                      }
                      slots={{
                        textField: AppTextField,
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                        },
                      }}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="place_of_birth"
                      label="Tempat Lahir"
                      variant="outlined"
                      value={values?.place_of_birth}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="kis"
                      label="No. Kartu Izin Start (KIS)"
                      variant="outlined"
                      value={values?.kis}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="pengprove"
                      label="Pengprove. IMI / ASN"
                      variant="outlined"
                      value={values?.pengprove}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="team_name"
                      label="Nama Team"
                      variant="outlined"
                      value={values?.team_name}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="emergency_contact"
                      label="Kontak Darurat"
                      type="number"
                      variant="outlined"
                      value={values?.emergency_contact}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="email"
                      label="Email Pembalap"
                      variant="outlined"
                      value={values?.email}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <DatePicker
                      disabled
                      label="Tanggal Latihan"
                      value={values?.date}
                      name="date"
                      onChange={(newValue) => setFieldValue("date", newValue)}
                      slots={{
                        textField: AppTextField,
                      }}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                        },
                      }}
                    />
                  </Grid>

                  {/* <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      select
                      fullWidth
                      label="Jenis Kendaraan / Kelas Latihan"
                      variant="outlined"
                      placeholder="Pilih Jenis Kendaraan / Jenis Latihan"
                      name="training_class_id"
                      value={values?.vehicle.merk || ""}
                      onChange={(event) => {
                        const selectedOption = latihanData.find(
                          (option) => option.id === event.target.value
                        );
                        setFieldValue("training_class_id", event.target.value);
                        setFieldValue("price", selectedOption?.price || null); // Set the price from the selected option
                      }}
                      onBlur={handleBlur}
                      SelectProps={{
                        native: true,
                        IconComponent: KeyboardArrowDown,
                      }}
                      helperText={
                        touched?.training_class_id && errors?.training_class_id
                      }
                      error={Boolean(
                        touched?.training_class_id && errors?.training_class_id
                      )}
                    >
                      <option value="" disabled></option>
                      {latihanData?.map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                    </AppTextField>
                  </Grid> */}

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="vehicle.merk"
                      label="Merek & Tipe Kendaraan"
                      variant="outlined"
                      value={values?.vehicle?.merk || ""}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="vehicle.livery"
                      label="Warna / Livery"
                      variant="outlined"
                      value={values?.vehicle?.livery}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Grid item sm={6} xs={12}>
                    <AppTextField
                      disabled
                      fullWidth
                      name="vehicle.machine_capacity"
                      label="Kapasitas Mesin"
                      variant="outlined"
                      value={values?.vehicle?.machine_capacity}
                      onChange={handleChange}
                    />
                  </Grid>

                  <Divider />
                </Grid>
              </Box>
            </form>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default LatihanVerifikasiDetail;
