import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import FlexBetween from "components/flexbox/FlexBetween";
import { H4, H5, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import LinearProgress from "@mui/material/LinearProgress";
import { CameraAlt, KeyboardArrowDown } from "@mui/icons-material";
import * as Yup from "yup"; // styled components
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { championshipsStore } from "store/championships/championshipStore";
// import { AuthContext } from "contexts/JWTAuth";
import { classStore } from "store/class/classStore";
import useAuth from "hooks/useAuth";
import { roleStore } from "store/role/roleStore";
import FormControlLabel from "@mui/material/FormControlLabel";

const StyledFormControl = styled(FormControlLabel)(() => ({
  "& .MuiTypography-root": {
    fontWeight: 600,
    fontSize: 14,
  },
}));

const AccessManagementEdit = () => {
  const {
    fetchPermissions,
    permissionsData,
    fetchRoleDetailData,
    roleDetailData,
    editRoleData,
  } = roleStore();
  const { accessToken } = useAuth();

  const location = useLocation();
  const navigate = useNavigate();
  const detailKejuaraan =
    location.state.editKejuaraan || location.state.detailKejuaraan;

  const createdDate = detailKejuaraan?.created_at
    ? new Date(detailKejuaraan.created_at)
    : null;
  const createdDateString = createdDate ? createdDate.toLocaleDateString() : "";

  const updatedDate = detailKejuaraan?.udpated_at
    ? new Date(detailKejuaraan?.udpated_at)
    : null;
  const updatedDateString = createdDate ? updatedDate.toLocaleDateString() : "";

  useEffect(() => {
    fetchRoleDetailData(detailKejuaraan?.id, accessToken);
  }, []);

  useEffect(() => {
    fetchPermissions({}, accessToken);
  }, []);

  const theme = useTheme();
  const initialValues = {
    id: detailKejuaraan?.id,
    name: detailKejuaraan?.name,
    permissions_id: roleDetailData?.permissions?.map(
      (permission) => permission.id
    ),
  };
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      editRoleData(values.id, payload, accessToken);
      navigate("/management/access-management");
    },
  });

  let payload = {
    name: values?.name,
    permission_ids: values?.permissions_id,
  };

  const [valuesRadio, setValuesRadio] = useState(initialValues);

  const handleChangePermissions = (event) => {
    const permissionId = Number(event.target.value); // Convert to a number
    const isChecked = event.target.checked;

    let updatedPermissions;
    if (isChecked) {
      updatedPermissions = [...values.permissions_id, permissionId];
    } else {
      updatedPermissions = values.permissions_id.filter(
        (id) => id !== permissionId
      );
    }

    setFieldValue("permissions_id", updatedPermissions);
  };

  const permissionLabels = {};

  permissionsData.forEach((permission) => {
    permissionLabels[permission.name] = permission.name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  });

  return (
    <>
      <Card
        sx={{
          mt: 3,
        }}
      >
        <H5 padding={3}>Detail Kejuaraan</H5>
        <Divider />

        <form onSubmit={handleSubmit}>
          <Box margin={3}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="id"
                  label="ID Role"
                  variant="outlined"
                  value={values.id}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="name"
                  label="Nama"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Divider />
              {permissionsData?.map((item, index) => (
                <Grid item sm={6} xs={12} key={item.id}>
                  <RadioGroup
                    row
                    name={`permissions_id.${item.id}`}
                    value={values.permissions_id}
                    onChange={handleChangePermissions}
                  >
                    <StyledFormControl
                      key={item.id}
                      value={item.id}
                      label={permissionLabels[item.name] || item.name}
                      control={<Radio />}
                      checked={values.permissions_id.includes(item.id)}
                    />
                  </RadioGroup>
                </Grid>
              ))}

              <Divider />
              <Grid item xs={12}>
                <FlexBetween>
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        navigate("/management/access-management");
                      }}
                    >
                      Kembali
                    </Button>
                  </Box>
                  <Box>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          ml: 2,
                        }}
                      >
                        Simpan
                      </Button>
                    </Grid>
                  </Box>
                </FlexBetween>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default AccessManagementEdit;
