import { lazy, Suspense, useContext } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import useSettings from "hooks/useSettings";
import LoadingScreen from "components/LoadingScreen";
import LayoutV1 from "layouts/layout-v1/DashboardLayout";
import LayoutV2 from "layouts/layout-v2/LayoutV2";
import LayoutV3 from "layouts/layout-v3/DashboardLayout";
import AuthContext from "contexts/JWTAuth";
import UserManagementDetail from "pages/user-management/user-management-detail";
import UserManagementEdit from "pages/user-management/kelas-edit";
import UserManagementCreate from "pages/user-management/kelas-create";
import AccessManagementDetail from "pages/access-management/access-management-detail";
import AccessManagementEdit from "pages/access-management/access-management-edit";
import AccessManagementCreate from "pages/access-management/access-management-create";
import ListStart from "pages/list-start/list-start";
import ListStartDetail from "pages/list-start/list-start-detail";
import AuthGuard from "page-sections/authentication/AuthGuard";
import Latihan from "pages/latihan/latihan";
import LatihanCreate from "pages/latihan/latihan-create";
import LatihanDetail from "pages/latihan/latihan-detail";
import LatihanEdit from "pages/latihan/latihan-edit";
import Libur from "pages/libur/libur";
import LiburanDetail from "pages/libur/liburan-detail";
import LiburanEdit from "pages/libur/liburan-edit";
import LiburanCreate from "pages/libur/liburan-create";
import LatihanInputForm from "pages/latihan-input-form/latihan-input-form";
import InputFormDetail from "pages/latihan-input-form/latihan-input-form-detail";
import LatihanVerifikasi from "pages/latihan-verifikasi/latihan-verifikasi";
import LatihanVerifikasiDetail from "pages/latihan-verifikasi/latihan-verifikasi-detail";
import LatihanDaftarPeserta from "pages/latihan-daftar-peserta/latihan-daftar-peserta";
import LatihanDaftarPesertaDetail from "pages/latihan-daftar-peserta/latihan-daftar-peserta-detail";
import LatihanDaftarPesertaEdit from "pages/latihan-daftar-peserta/latihan-daftar-peserta-edit";
import Status from "pages/status/status";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

const Kejuaraan = Loadable(lazy(() => import("./pages/kejuaraan/kejuaraan")));
const Kelas = Loadable(lazy(() => import("./pages/kelas/kelas")));
const KejuaraanEdit = Loadable(
  lazy(() => import("./pages/kejuaraan/kejuaraan-edit"))
);
const KejuaraanCreate = Loadable(
  lazy(() => import("./pages/kejuaraan/kejuaraan-create"))
);
const KelasEdit = Loadable(lazy(() => import("./pages/kelas/kelas-edit")));
const TambahPeserta = Loadable(
  lazy(() => import("./pages/input-form/tambah-peserta"))
);
const InputFormEdit = Loadable(
  lazy(() => import("./pages/input-form/input-form-edit"))
);
const KelasDetail = Loadable(lazy(() => import("./pages/kelas/kelas-detail"))); // account

const KelasCreate = Loadable(lazy(() => import("./pages/kelas/kelas-create")));
const Verifikasi = Loadable(
  lazy(() => import("./pages/verifikasi/verifikasi"))
); // user and contact

const KonfirmasiPembayaran = Loadable(
  lazy(() => import("./pages/input-form/konfirmasi-pembayaran"))
);
const DaftarPeserta = Loadable(
  lazy(() => import("./pages/daftar-peserta/daftar-peserta"))
);
const VerifikasiDetail = Loadable(
  lazy(() => import("./pages/verifikasi/verifikasi-detail"))
);
const EditDaftarPeserta = Loadable(
  lazy(() => import("./pages/daftar-peserta/editDaftarPeserta"))
);
const AccessManagement = Loadable(
  lazy(() => import("./pages/access-management/access-management"))
);
const UserManagement = Loadable(
  lazy(() => import("./pages/user-management/user-management"))
);
const ContactGrid = Loadable(
  lazy(() => import("./pages/contact-list/contact-grid-view"))
); // profile

const Profile = Loadable(lazy(() => import("./pages/profiles/profile")));
const ProfileV2 = Loadable(lazy(() => import("./pages/profiles/profile-v2"))); // profile

const DataTableV2 = Loadable(
  lazy(() => import("./pages/data-table/data-table-v2"))
); // invoice

const InvoiceList = Loadable(lazy(() => import("./pages/invoice/list")));
const InvoiceList2 = Loadable(
  lazy(() => import("./pages/invoice/invoice-list-v2"))
);
const CreateInvoice = Loadable(lazy(() => import("./pages/invoice/create")));
const CreateInvoice2 = Loadable(
  lazy(() => import("./pages/invoice/add-invoice-v2"))
);
const InvoiceDetails = Loadable(lazy(() => import("./pages/invoice/details")));
const InvoiceDetails2 = Loadable(
  lazy(() => import("./pages/invoice/invoice-details-v2"))
); // eCommerce

const KejuaraanDetail = Loadable(
  lazy(() => import("./pages/kejuaraan/kejuaraan-detail"))
);

const Shop = Loadable(lazy(() => import("./pages/ecommerce/shop")));
const ShopV2 = Loadable(lazy(() => import("./pages/ecommerce/shop-v2")));
const Cart = Loadable(lazy(() => import("./pages/ecommerce/Cart")));
const Payment = Loadable(lazy(() => import("./pages/ecommerce/payment")));
const Checkout = Loadable(lazy(() => import("./pages/ecommerce/checkout")));
const CheckoutV2 = Loadable(
  lazy(() => import("./pages/ecommerce/checkout-v2"))
);
const ProductDetails = Loadable(
  lazy(() => import("./pages/ecommerce/product-details"))
);
const BillingAddress = Loadable(
  lazy(() => import("./pages/ecommerce/billing-address"))
);
const PaymentComplete = Loadable(
  lazy(() => import("./pages/ecommerce/payment-complete"))
);
const PaymentCompleteV2 = Loadable(
  lazy(() => import("./pages/ecommerce/payment-complete-v2"))
); // pricing

const Pricing = Loadable(lazy(() => import("./pages/pricing"))); // pricing

const TodoList = Loadable(lazy(() => import("./pages/todo-list"))); // pricing

const Calendar = Loadable(lazy(() => import("./pages/calendar"))); // authentication

const Login = Loadable(lazy(() => import("./pages/authentication/login")));
const LoginV2 = Loadable(lazy(() => import("./pages/authentication/login-v2")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/register"))
);
const RegisterV2 = Loadable(
  lazy(() => import("./pages/authentication/register-v2"))
);
const NewPassword = Loadable(
  lazy(() => import("./pages/authentication/new-password"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/authentication/forget-password"))
);
const ForgetPasswordV2 = Loadable(
  lazy(() => import("./pages/authentication/forget-password-v2"))
);
const TwoStepVerify = Loadable(
  lazy(() => import("./pages/authentication/two-step-verification"))
); // admin ecommerce

const ProductList = Loadable(
  lazy(() => import("./pages/admin-ecommerce/product-list"))
);
const ProductGrid = Loadable(
  lazy(() => import("./pages/admin-ecommerce/product-grid"))
);
const CreateProduct = Loadable(
  lazy(() => import("./pages/admin-ecommerce/create-product"))
);
const CustomerManagement = Loadable(
  lazy(() => import("./pages/admin-ecommerce/customer-management"))
);
const OrderManagement = Loadable(
  lazy(() => import("./pages/admin-ecommerce/order-management"))
);
const ProductManagement = Loadable(
  lazy(() => import("./pages/admin-ecommerce/product-management"))
); // chat

const ChatV1 = Loadable(lazy(() => import("./pages/chats/chat-v1")));
const ChatV2 = Loadable(lazy(() => import("./pages/chats/chat-v2"))); // user projects

const ProjectDetails = Loadable(
  lazy(() => import("./pages/projects/project-details"))
);
const ProjectV1 = Loadable(lazy(() => import("./pages/projects/project-v1")));
const ProjectV2 = Loadable(lazy(() => import("./pages/projects/project-v2")));
const TeamMember = Loadable(lazy(() => import("./pages/projects/team-member")));
const ProjectV3 = Loadable(lazy(() => import("./pages/projects/project-v3"))); // Landing page
const InputForm = Loadable(lazy(() => import("./pages/input-form/input-form")));

const AboutPage = Loadable(lazy(() => import("./pages/about")));
const ContactPage = Loadable(lazy(() => import("./pages/contact")));
const PricingPage = Loadable(lazy(() => import("./pages/pricing-v2")));
const PrivacyPage = Loadable(lazy(() => import("./pages/privacy"))); // 404/Error page

const Error = Loadable(lazy(() => import("./pages/404")));

const ActiveLayout = () => {
  const { settings } = useSettings(); // console.log(settings);

  switch (settings.activeLayout) {
    case "layout1":
      return <LayoutV1 />;

    case "layout2":
      return <LayoutV2 />;

    case "layout3":
      return <LayoutV3 />;

    default:
      return <LayoutV1 />;
  }
};

const Routes = () => {
  return [
    ...authRoutes,
    {
      path: "kejuaraan",
      element: <ActiveLayout />,
      children: kejuaraanRoutes,
    },
    {
      path: "latihan",
      element: <ActiveLayout />,
      children: latihanRoutes,
    },
    {
      path: "status-pendaftaran",
      element: <ActiveLayout />,
      children: statusRoutes,
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};

const authRoutes = [
  {
    path: "/",
    element: <Navigate to="/kejuaraan/input-form" />,
  },
];

const statusRoutes = [
  {
    path: "",
    element: <Status />,
  },
];

const kejuaraanRoutes = [
  {
    path: "input-form",
    element: <InputForm />,
  },
  {
    path: "input-form-edit",
    element: <InputFormEdit />,
  },
  {
    path: "tambah-peserta",
    element: <TambahPeserta />,
  },
  {
    path: "konfirmasi-pembayaran",
    element: <KonfirmasiPembayaran />,
  },
  {
    path: "daftar-peserta-kejuaraan",
    element: <Kelas />,
  },
];

const latihanRoutes = [
  {
    path: "input-form",
    element: <LatihanInputForm />,
  },
  {
    path: "input-form-detail",
    element: <InputFormDetail />,
  },
  {
    path: "verifikasi",
    element: <LatihanVerifikasi />,
  },
  {
    path: "verifikasi-detail/:id",
    element: <LatihanVerifikasiDetail />,
  },
  {
    path: "daftar-peserta",
    element: <LatihanDaftarPeserta />,
  },
  {
    path: "daftar-peserta-detail",
    element: <LatihanDaftarPesertaDetail />,
  },
  {
    path: "daftar-peserta-edit",
    element: <LatihanDaftarPesertaEdit />,
  },
];

export default Routes;
