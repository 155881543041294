import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  Snackbar,
  styled,
  useTheme,
} from "@mui/material";
import { Field, useField, useFormik } from "formik";
import FlexBetween from "components/flexbox/FlexBetween";
import { H4, H5, H6, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import LinearProgress from "@mui/material/LinearProgress";
import { CameraAlt, KeyboardArrowDown } from "@mui/icons-material";
import * as Yup from "yup"; // styled components
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { classStore } from "store/class/classStore";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { set } from "lodash";
import ImageUploadInput from "components/input-fields/ImageUploadInput";
import { fileUploadStore } from "store/fileUpload/fileUploadStore";
import { inputFormStore } from "store/inputForm/inputFormStore";
import useAuth from "hooks/useAuth";
import CustomRangePicker from "components/input-fields/DateRange";
import { latihanStore } from "store/latihan/latihanStore";

const LatihanDaftarPesertaEdit = () => {
  const { createClass, fetchClassData, classData } = classStore();
  const { createInputFormPeserta } = inputFormStore();
  const { accessToken } = useAuth();
  const [selectedBracket, setSelectedBracket] = useState("");
  const [isBracketSelected, setIsBracketSelected] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const { setFileUploadData, fileUploadData } = fileUploadStore();
  const {
    fetchLatihanData,
    latihanData,
    fetchLatihanDaftarPesertaDetailData,
    latihanVerifikasiDetailData,
  } = latihanStore();
  const location = useLocation();
  const detailKejuaraan = location.state.detailKejuaraan;
  console.log(detailKejuaraan);

  useEffect(() => {
    fetchLatihanDaftarPesertaDetailData(detailKejuaraan?.id, accessToken);
  }, [accessToken, fetchLatihanData]);

  useEffect(() => {
    let payload = {
      status: "active",
    };
    fetchLatihanData(payload, accessToken);
  }, [accessToken, fetchLatihanData]);

  const navigate = useNavigate();

  const theme = useTheme();
  const initialValues = {
    training_class_id: latihanVerifikasiDetailData?.training_class_id,
    name: latihanVerifikasiDetailData?.name,
    email: latihanVerifikasiDetailData?.email,
    date_of_birth: latihanVerifikasiDetailData?.date_of_birth
      ? new Date(latihanVerifikasiDetailData.date_of_birth)
      : new Date(),
    date: latihanVerifikasiDetailData.date
      ? new Date(latihanVerifikasiDetailData.date)
      : new Date(),
    place_of_birth: latihanVerifikasiDetailData?.place_of_birth,
    team_name: latihanVerifikasiDetailData?.team_name,
    emergency_contact: latihanVerifikasiDetailData?.emergency_contact,
    vehicle_merk: "",
    vehicle_livery: "",
    vehicle_machine_capacity: "",
    price: latihanVerifikasiDetailData?.price,
    address: latihanVerifikasiDetailData?.address,
    kis: latihanVerifikasiDetailData?.kis,
    pengprove: latihanVerifikasiDetailData?.pengprove,
    vehicle: {
      merk: latihanVerifikasiDetailData?.vehicle?.merk,
      livery: latihanVerifikasiDetailData?.vehicle?.livery,
      machine_capacity: latihanVerifikasiDetailData?.vehicle?.machine_capacity,
    },
  };

  const validationSchema = Yup.object().shape({
    training_class_id: Yup.string().required("Wajib Diisi"),
    name: Yup.string().required("Wajib Diisi"),
    email: Yup.string()
      .email("Must be a valid email")
      .max(255)
      .required("Email is required"),
    date_of_birth: Yup.string().required("Wajib Diisi"),
    pengprove: Yup.string().required("Wajib Diisi"),
    date: Yup.string().required("Wajib Diisi"),
    place_of_birth: Yup.string().required("Wajib Diisi"),
    address: Yup.string().required("Wajib Diisi"),
    kis: Yup.string().required("Wajib Diisi"),
    pengprove: Yup.string().required("Wajib Diisi"),
    emergency_contact: Yup.string().required("Wajib Diisi"),
    vehicle_merk: Yup.string().required("Wajib Diisi"),
    team_name: Yup.string().required("Wajib Diisi"),
    vehicle_machine_capacity: Yup.string().required("Wajib Diisi"),
    vehicle_livery: Yup.string().required("Wajib Diisi"),
  });

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
    resetForm,
  } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      let payload = {
        training_class_id: values?.training_class_id,
        name: values?.name,
        email: values?.email,
        date_of_birth: values?.date_of_birth,
        place_of_birth: values?.place_of_birth,
        address: values?.address,
        date: values?.date,
        kis: values?.kis,
        team_name: values?.team_name,
        pengprove: values?.pengprove,
        emergency_contact: values?.emergency_contact,
        file_photo: values?.file_photo,
        vehicle_machine_capacity: values?.vehicle?.machine_capacity,
        vehicle_livery: values?.values?.livery,
        vehicle_merk: values?.vehicle?.merk,
        price: values.price,
      };
      navigate("/latihan/input-form-detail", { state: { payload } });
    },
  });

  return (
    <>
      <Card
        sx={{
          mt: 3,
        }}
      >
        <H5 padding={3}>Data Pembalap</H5>
        <Divider />

        <form onSubmit={handleSubmit}>
          <Box margin={3}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="name"
                  label="Nama Pembalap (Sesuai KTP)"
                  variant="outlined"
                  value={values?.name}
                  onChange={handleChange}
                  helperText={touched?.name && errors?.name}
                  error={Boolean(touched?.name && errors?.name)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="address"
                  label="Alamat (Sesuai KTP)"
                  variant="outlined"
                  value={values?.address}
                  onChange={handleChange}
                  helperText={touched.address && errors.address}
                  error={touched.address && Boolean(errors.address)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <DatePicker
                  label="Tanggal Lahir"
                  name="date_of_birth"
                  value={values?.date_of_birth}
                  onChange={(newValue) =>
                    setFieldValue("date_of_birth", newValue)
                  }
                  slots={{
                    textField: AppTextField,
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  helperText={touched?.date_of_birth && errors?.date_of_birth}
                  error={Boolean(
                    touched?.date_of_birth && errors?.date_of_birth
                  )}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="place_of_birth"
                  label="Tempat Lahir"
                  variant="outlined"
                  value={values?.place_of_birth}
                  onChange={handleChange}
                  helperText={touched?.place_of_birth && errors?.place_of_birth}
                  error={Boolean(
                    touched?.place_of_birth && errors?.place_of_birth
                  )}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="kis"
                  label="No. Kartu Izin Start (KIS)"
                  variant="outlined"
                  value={values?.kis}
                  onChange={handleChange}
                  helperText={touched?.kis && errors?.kis}
                  error={Boolean(touched?.kis && errors?.kis)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="pengprove"
                  label="Pengprove. IMI / ASN"
                  variant="outlined"
                  value={values?.pengprove}
                  onChange={handleChange}
                  helperText={touched?.pengprove && errors?.pengprove}
                  error={Boolean(touched?.pengprove && errors?.pengprove)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="team_name"
                  label="Nama Team"
                  variant="outlined"
                  value={values?.team_name}
                  onChange={handleChange}
                  helperText={touched?.team_name && errors?.team_name}
                  error={Boolean(touched?.team_name && errors?.team_name)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="emergency_contact"
                  label="Kontak Darurat"
                  type="number"
                  variant="outlined"
                  value={values?.emergency_contact}
                  onChange={handleChange}
                  helperText={
                    touched?.emergency_contact && errors?.emergency_contact
                  }
                  error={Boolean(
                    touched?.emergency_contact && errors?.emergency_contact
                  )}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="email"
                  label="Email Pembalap"
                  variant="outlined"
                  value={values?.email}
                  onChange={handleChange}
                  helperText={touched?.email && errors?.email}
                  error={Boolean(touched?.email && errors?.email)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <DatePicker
                  label="Tanggal Latihan"
                  value={values?.date}
                  name="date"
                  onChange={(newValue) => setFieldValue("date", newValue)}
                  slots={{
                    textField: AppTextField,
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                  helperText={touched?.date && errors?.date}
                  error={Boolean(touched?.date && errors?.date)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  select
                  fullWidth
                  label="Jenis Kendaraan / Kelas Latihan"
                  variant="outlined"
                  placeholder="Pilih Jenis Kendaraan / Jenis Latihan"
                  name="training_class_id"
                  value={values?.training_class_id || ""}
                  onChange={(event) => {
                    const selectedOption = latihanData.find(
                      (option) => option.id === event.target.value
                    );
                    setFieldValue("training_class_id", event.target.value);
                    setFieldValue("price", selectedOption?.price || null); // Set the price from the selected option
                  }}
                  onBlur={handleBlur}
                  SelectProps={{
                    native: true,
                    IconComponent: KeyboardArrowDown,
                  }}
                  helperText={
                    touched?.training_class_id && errors?.training_class_id
                  }
                  error={Boolean(
                    touched?.training_class_id && errors?.training_class_id
                  )}
                >
                  <option value="" disabled></option>
                  {latihanData?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </AppTextField>
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="vehicle.merk"
                  label="Merek & Tipe Kendaraan"
                  variant="outlined"
                  value={values?.vehicle.merk}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={touched?.vehicle.merk && errors?.vehicle.merk}
                  error={Boolean(touched?.vehicle.merk && errors?.vehicle.merk)}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="vehicle.livery"
                  label="Warna / Livery"
                  variant="outlined"
                  onBlur={handleBlur}
                  value={values?.vehicle.livery}
                  onChange={handleChange}
                  helperText={touched?.vehicle.livery && errors?.vehicle.livery}
                  error={Boolean(
                    touched?.vehicle.livery && errors?.vehicle.livery
                  )}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="vehicle.machine_capacity"
                  label="Kapasitas Mesin"
                  variant="outlined"
                  value={values?.vehicle.machine_capacity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    touched?.vehicle.machine_capacity &&
                    errors?.vehicle.machine_capacity
                  }
                  error={Boolean(
                    touched?.vehicle.machine_capacity &&
                      errors?.vehicle.machine_capacity
                  )}
                />
              </Grid>

              <Divider />
              <Grid item xs={12}>
                <FlexBetween>
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        navigate("/dashboard/latihan");
                      }}
                    >
                      Kembali
                    </Button>
                  </Box>
                  <Box>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          ml: 2,
                        }}
                      >
                        Simpan
                      </Button>
                    </Grid>
                  </Box>
                </FlexBetween>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default LatihanDaftarPesertaEdit;
