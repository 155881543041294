import React, { useContext, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { H3, H4, H5, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import { KeyboardArrowDown } from "@mui/icons-material";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import FlexBetween from "components/flexbox/FlexBetween";
import { useNavigate } from "react-router-dom";
import { statusStore } from "store/status/statusStore";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "150px",
  width: "100%",
  padding: "20px",
  backgroundColor: "#f5f5f5",
  borderRadius: "10px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: "16px",
});

const getStatusText = (statusValue) => {
  switch (statusValue) {
    case "approved":
      return "Telah Disetujui";
    case "request":
      return "Sedang Diproses";
    case "rejected":
      return "Ditolak";
    default:
      return "Status Tidak Diketahui";
  }
};

const Status = () => {
  const { fetchStatusPendaftaran, statusData, loading } = statusStore();

  const navigate = useNavigate();

  const initialValues = {
    form_code: "",
  };

  const { values, errors, handleSubmit, handleChange, handleBlur, touched } =
    useFormik({
      initialValues,
      onSubmit: (values) => {
        let payload = {
          form_code: values.form_code,
        };
        fetchStatusPendaftaran(payload);
      },
    });

  return (
    <Card
      sx={{
        mt: 3,
      }}
    >
      <H5 padding={3}>Status Pendaftaran</H5>
      <Divider />

      <Box margin={3}>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item sm={12} xs={12}>
              <AppTextField
                fullWidth
                name="form_code"
                id="form_code"
                label="Token"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.form_code}
              />
            </Grid>

            <Grid item xs={12}>
              <FlexBetween>
                <Box>
                  {loading ? (
                    <LoadingButton loading variant="contained">
                      Search
                    </LoadingButton>
                  ) : (
                    <Button type="submit" variant="contained">
                      Search
                    </Button>
                  )}
                </Box>
              </FlexBetween>
            </Grid>
          </Grid>
        </form>
        <Divider sx={{ marginTop: "8px" }} />
        <Grid item xs={12}>
          {statusData ? (
            <CenteredBox>
              <H3 style={{ color: "#0077b6" }}>
                Status Anda Saat Ini : {getStatusText(statusData?.status)}
              </H3>
              <H4 style={{ color: "#0077b6" }}>
                Mohon periksa email anda secara berkala &
              </H4>
              <H4 style={{ color: "#0077b6" }}>
                Terimakasih telah mendaftar melalui aplikasi SICOF
              </H4>
            </CenteredBox>
          ) : null}
        </Grid>
      </Box>
    </Card>
  );
};

export default Status;
