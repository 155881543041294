import { BASE_URL_ENDPOINT } from "constant/constanta";
import { create } from "zustand";
import axios from "axios";
import { toastStore } from "store/toast/toastStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const inputFormStore = create((set) => ({
  inputFormData: [],
  inputFormEventID: [],
  inputFormParticipantsData: [],
  inputFormParticipantsTotalData: [],
  resetInputFormParticipants: [],
  daftarPesertaDetail: [],
  detailKonfirmasiData: [],
  loading: false,
  error: null,

  fetchParticipantsData: async (payload) => {
    try {
      set({ loading: true });
      const response = await axios.get(
        `${BASE_URL_ENDPOINT}/api/customer/event/participants`,
        {
          params: { ...payload },
        }
      );
      const data = response.data.data;
      const totalData = response.data.total_data;
      set({ inputFormParticipantsData: data, inputFormParticipantsTotalData: totalData, loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },

  fetchDetailPeserta: async (payload) => {
    try {
      set({ loading: true });
      const response = await axios.get(
        `${BASE_URL_ENDPOINT}/api/customer/event/participant/detail/${payload}`
      );
      const data = response.data.data;
      set({ daftarPesertaDetail: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },

  fetchDetailEvent: async (id) => {
    try {
      set({ loading: true });
      const response = await axios.get(
        `${BASE_URL_ENDPOINT}/api/customer/event/detail/${id}`
      );
      const data = response.data.data;
      set({ detailKonfirmasiData: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },

  submitFormPeserta: async (id, payload, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/customer/event/submit/${id}`,
        payload
      );

      if (response.data.code !== 200) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(
          "Terimakasih sudah mendaftar, silahkan check Email anda",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
        navigate("/kejuaraan/input-form");
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  createInputForm: async (payload, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/customer/event/create`,
        payload
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        navigate("/kejuaraan/tambah-peserta", {
          state: { teamID: data },
        });
        set({ inputFormEventID: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  createInputFormPeserta: async (id, payload, authToken, navigate, idTeam) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/customer/event/participant/create/${id}`,
        payload
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        set({ inputFormData: data, loading: false });
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        navigate("/kejuaraan/input-form", {
          state: { teamIdParticipants: idTeam },
        });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  submitInputFormPayment: async (id, payload) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/customer/event/payment/${id}`,
        payload
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        set({ loading: false });
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  editInputFormData: async (id, payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/customer/event/update/${id}`,
        payload
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        set({ inputFormEventID: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },

  editDaftarPeserta: async (id, payload, navigate, dataId) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/customer/event/participant/update/${id}`,
        payload
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ inputFormParticipantsData: data, loading: false });
        navigate("/kejuaraan/input-form", {
          state: { teamIdParticipants: dataId },
        });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  massInactiveClassData: async (ids, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/class/set-mass-inactive`,
        {
          class_ids: ids,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ classData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },

  deleteInputFormData: async (id, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/customer/event/participant/delete/${id}`
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          inputFormParticipantsData: state.inputFormParticipantsData.filter(
            (dataInputForm) => dataInputForm.id !== id
          ),
          loading: false,
        }));
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },

  massDeleteClassData: async (ids, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/class/mass-delete`,
        {
          data: {
            class_ids: ids,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          classData: state.classData.filter(
            (dataClass) => !ids.includes(dataClass.id)
          ),
          loading: false,
        }));
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
    }
  },

  downloadClassData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/class/download`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "kejuaraan_data.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      set({ loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },
}));
