const ErrorFallback = () => {
  return (
    <div>
      <h1>Unexpected Application Error!</h1>
      <p>Sorry, something went wrong.</p>
    </div>
  );
};

export default ErrorFallback;
