import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import FlexBetween from "components/flexbox/FlexBetween";
import { H4, H5, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { classStore } from "store/class/classStore";
import KelasDelete from "./kelas-delete";
import useAuth from "hooks/useAuth";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { KeyboardArrowDown } from "@mui/icons-material";
import { latihanStore } from "store/latihan/latihanStore";
import { latihanDaftarPesertaStore } from "store/latihanDaftarPeserta/latihanDaftarPesertaStore";
// import KejuaraanDelete from "./kejuaraan-delete";

const LatihanDaftarPesertaDetail = () => {
  const { downloadClassData } = classStore();
  const { accessToken } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { fetchLatihanData, latihanData } = latihanStore();
  const {
    fetchLatihanDaftarPeserta,
    fetchLatihanDaftarPesertaDetailData,
    latihanDaftarPesertaDetailData,
    latihanDaftarPesertaData,
  } = latihanDaftarPesertaStore();

  useEffect(() => {
    let payload = {
      status: "active",
    };
    fetchLatihanData(payload, accessToken);
  }, [accessToken, fetchLatihanData]);

  const location = useLocation();
  const navigate = useNavigate();
  const detailKejuaraan = location.state.detailKejuaraan;

  console.log(detailKejuaraan);

  const theme = useTheme();
  const initialValues = {
    training_class_id: detailKejuaraan.training_class_id,
    name: detailKejuaraan.name,
    email: detailKejuaraan.email,
    date_of_birth: detailKejuaraan.date_of_birth
      ? new Date(detailKejuaraan.date_of_birth)
      : new Date(),
    date: detailKejuaraan.date ? new Date(detailKejuaraan.date) : new Date(),
    place_of_birth: detailKejuaraan.place_of_birth,
    team_name: detailKejuaraan.team_name,
    emergency_contact: detailKejuaraan.emergency_contact,
    vehicle_merk: detailKejuaraan.vehicle_merk,
    vehicle_livery: detailKejuaraan.vehicle_livery,
    vehicle_machine_capacity: detailKejuaraan.vehicle_machine_capacity,
    payment_url_file: "",
    price: detailKejuaraan.price,
    address: detailKejuaraan.address,
    kis: detailKejuaraan.kis,
    pengprove: detailKejuaraan.pengprove,
    price: detailKejuaraan.price,
  };
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {},
  });

  let payload = {
    training_class_id: values?.training_class_id,
    name: values?.name,
    email: values?.email,
    date_of_birth: values?.date_of_birth,
    place_of_birth: values?.place_of_birth,
    address: values?.address,
    date: values?.date,
    kis: values?.kis,
    team_name: values?.team_name,
    pengprove: values?.pengprove,
    emergency_contact: values?.emergency_contact,
    payment_url_file: values?.payment_url_file,
    vehicle_machine_capacity: values?.vehicle_machine_capacity,
    vehicle_livery: values?.vehicle_livery,
    vehicle_merk: values?.vehicle_merk,
    price: values.price,
  };

  const handleDownload = () => {
    downloadClassData(payload, accessToken);
  };

  return (
    <>
      <Card
        sx={{
          mt: 3,
        }}
      >
        <H5 padding={3}>Detail Daftar Peserta Latihan</H5>
        <Divider />

        <form onSubmit={handleSubmit}>
          <Box margin={3}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="name"
                  label="Nama Pembalap (Sesuai KTP)"
                  variant="outlined"
                  value={values?.name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  disabled
                  name="address"
                  label="Alamat (Sesuai KTP)"
                  variant="outlined"
                  value={values?.address}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <DatePicker
                  disabled
                  label="Tanggal Lahir"
                  name="date_of_birth"
                  value={values?.date_of_birth}
                  onChange={(newValue) =>
                    setFieldValue("date_of_birth", newValue)
                  }
                  slots={{
                    textField: AppTextField,
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="place_of_birth"
                  label="Tempat Lahir"
                  variant="outlined"
                  value={values?.place_of_birth}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="kis"
                  label="No. Kartu Izin Start (KIS)"
                  variant="outlined"
                  value={values?.kis}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="pengprove"
                  label="Pengprove. IMI / ASN"
                  variant="outlined"
                  value={values?.pengprove}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="team_name"
                  label="Nama Team"
                  variant="outlined"
                  value={values?.team_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="emergency_contact"
                  label="Kontak Darurat"
                  type="number"
                  variant="outlined"
                  value={values?.emergency_contact}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="email"
                  label="Email Pembalap"
                  variant="outlined"
                  value={values?.email}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <DatePicker
                  disabled
                  label="Tanggal Latihan"
                  value={values?.date}
                  name="date"
                  onChange={(newValue) => setFieldValue("date", newValue)}
                  slots={{
                    textField: AppTextField,
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  select
                  fullWidth
                  label="Jenis Kendaraan / Kelas Latihan"
                  variant="outlined"
                  placeholder="Pilih Jenis Kendaraan / Jenis Latihan"
                  name="training_class_id"
                  value={values?.training_class_id || ""}
                  onChange={(event) => {
                    const selectedOption = latihanData.find(
                      (option) => option.id === event.target.value
                    );
                    setFieldValue("training_class_id", event.target.value);
                    setFieldValue("price", selectedOption?.price || null); // Set the price from the selected option
                  }}
                  onBlur={handleBlur}
                  SelectProps={{
                    native: true,
                    IconComponent: KeyboardArrowDown,
                  }}
                  helperText={
                    touched?.training_class_id && errors?.training_class_id
                  }
                  error={Boolean(
                    touched?.training_class_id && errors?.training_class_id
                  )}
                >
                  <option value="" disabled></option>
                  {latihanData?.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </AppTextField>
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="vehicle_merk"
                  label="Merek & Tipe Kendaraan"
                  variant="outlined"
                  value={values?.vehicle_merk}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="vehicle_livery"
                  label="Warna / Livery"
                  variant="outlined"
                  value={values?.vehicle_livery}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  disabled
                  fullWidth
                  name="vehicle_machine_capacity"
                  label="Kapasitas Mesin"
                  variant="outlined"
                  value={values?.vehicle_machine_capacity}
                  onChange={handleChange}
                />
              </Grid>

              <Divider />

              <Grid item xs={12}>
                <FlexBetween>
                  <Box>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={handleDownload}
                    >
                      Download
                    </Button>
                  </Box>
                  <Box>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          navigate("/latihan/daftar-peserta");
                        }}
                      >
                        Kembali
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          navigate("/dashboard/hari-libur-edit", {
                            state: { editKejuaraan: latihanDaftarPesertaData },
                          });
                        }}
                        sx={{
                          ml: 2,
                        }}
                      >
                        Ubah
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{
                          ml: 2,
                        }}
                        onClick={() => {
                          setOpenModal(true);
                          setSelectedId(detailKejuaraan?.id);
                        }}
                      >
                        Hapus
                      </Button>
                      {/* <LatihanDelete
                      open={openModal}
                      setOpen={setOpenModal}
                      selectedId={selectedId}
                    /> */}
                    </Grid>
                  </Box>
                </FlexBetween>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default LatihanDaftarPesertaDetail;
