import { Button, Stack, styled, Card, Divider, Grid } from "@mui/material";
import AppModal from "components/AppModal";
import { useContext, useState } from "react"; // custom styled components
import { Box } from "@mui/system";
import AppCheckBox from "components/AppCheckBox";
import { H5, H6, Tiny } from "components/Typography";
import { championshipsStore } from "store/championships/championshipStore";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { classStore } from "store/class/classStore";
import useAuth from "hooks/useAuth";
import AppTextField from "components/input-fields/AppTextField";
import { verifikasiStore } from "store/verifikasi/verifikasiStore";
import { latihanVerifikasiStore } from "store/latihanVerifikasi/latihanVerifikasiStore";

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: 300,
  },
}));

const VerifikasiTolak = ({ open, setOpen, dataID }) => {
  const navigate = useNavigate();
  const { accessToken } = useAuth();
  const { updateStatusEvent } = latihanVerifikasiStore();
  const initialValues = {
    message: "",
  };

  const { values, errors, handleSubmit, handleChange, handleBlur, touched } =
    useFormik({
      initialValues,
      onSubmit: (values) => {
        updateStatusEvent(dataID, payload, accessToken, navigate);
        setOpen(false);
      },
    });

  let payload = {
    status: "rejected",
    message: values.message,
  };

  return (
    <StyledAppModal open={open} handleClose={() => setOpen(false)}>
      <Card sx={{ pb: 3 }}>
        <Box padding={3}>
          <H5 mb={1}>Tolak Transaksi ini?</H5>
          <Tiny lineHeight={1.7} maxWidth={600}>
            Apakah kamu yakin untuk menolak transaksi ini?
          </Tiny>
        </Box>

        <Divider />

        <Stack px={3} direction="row" spacing={2}>
          <form onSubmit={handleSubmit}>
            <AppTextField
              fullWidth
              multiline
              name="message"
              id="message"
              label="Alasan Penolakan"
              variant="outlined"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Button
              variant="contained"
              color="error"
              type="submit"
              sx={{ marginTop: "16px", marginRight: "16px" }}
            >
              Tolak
            </Button>
            <Button
              variant="outlined"
              onClick={() => setOpen(false)}
              sx={{ marginTop: "16px" }}
            >
              Batal
            </Button>
          </form>
        </Stack>
      </Card>
    </StyledAppModal>
  );
};

export default VerifikasiTolak;
