import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Radio,
  RadioGroup,
  Snackbar,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import FlexBetween from "components/flexbox/FlexBetween";
import { H4, H5, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import LinearProgress from "@mui/material/LinearProgress";
import { CameraAlt, CheckBox, KeyboardArrowDown } from "@mui/icons-material";
import * as Yup from "yup"; // styled components
import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import { AuthContext } from "contexts/JWTAuth";
import { classStore } from "store/class/classStore";
import useAuth from "hooks/useAuth";
import FormControlLabel from "@mui/material/FormControlLabel";
import { roleStore } from "store/role/roleStore";

const StyledFormControl = styled(FormControlLabel)(() => ({
  "& .MuiTypography-root": {
    fontWeight: 600,
    fontSize: 14,
  },
}));

const AccessManagementCreate = () => {
  const { fetchPermissions, permissionsData, createRole } = roleStore();
  const { accessToken } = useAuth();
  const [selectedPermission, setSelectedPermission] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPermissions({}, accessToken);
  }, []);

  const theme = useTheme();
  const initialValues = {
    id: "",
    name: "",
    permissions_id: [],
  };
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      createRole(payload, accessToken);
      navigate("/management/access-management");
    },
  });

  let payload = {
    name: values?.name,
    permission_ids: values?.permissions_id,
  };

  console.log(values, "values");

  const [valuesRadio, setValuesRadio] = useState(initialValues);

  const handleChangePermissions = (event) => {
    const permissionId = Number(event.target.value); // Convert to a number
    const isChecked = event.target.checked;

    let updatedPermissions;
    if (isChecked) {
      updatedPermissions = [...values.permissions_id, permissionId];
    } else {
      updatedPermissions = values.permissions_id.filter(
        (id) => id !== permissionId
      );
    }

    setFieldValue("permissions_id", updatedPermissions);
  };

  const permissionLabels = {};

  permissionsData.forEach((permission) => {
    permissionLabels[permission.name] = permission.name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  });

  return (
    <>
      <Card
        sx={{
          mt: 3,
        }}
      >
        <H5 padding={3}>Tambah Manajemen Akses</H5>
        <Divider />

        <form onSubmit={handleSubmit}>
          <Box margin={3}>
            <Grid container spacing={3}>
              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="id"
                  label="ID Role"
                  variant="outlined"
                  value={values.id}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <AppTextField
                  fullWidth
                  name="name"
                  label="Nama"
                  variant="outlined"
                  value={values.name}
                  onChange={handleChange}
                  helperText={touched.name && errors.name}
                  error={Boolean(touched.name && errors.name)}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid item sm={16} xs={12}>
                <H5 padding={3}>Pilih Akses Menu</H5>
                <Divider />
                {permissionsData?.map((item, index) => (
                  <Grid item sm={6} xs={12} key={item.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value={item.id}
                          checked={values.permissions_id.includes(item.id)}
                          onChange={handleChangePermissions}
                        />
                      }
                      label={permissionLabels[item.name] || item.name}
                    />
                  </Grid>
                ))}
              </Grid>

              <Divider />
              <Grid item xs={12}>
                <FlexBetween>
                  <Box>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        navigate("/management/access-management");
                      }}
                    >
                      Kembali
                    </Button>
                  </Box>
                  <Box>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{
                          ml: 2,
                        }}
                      >
                        Simpan
                      </Button>
                    </Grid>
                  </Box>
                </FlexBetween>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Card>
    </>
  );
};

export default AccessManagementCreate;
