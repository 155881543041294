import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  styled,
  useTheme,
} from "@mui/material";
import { useFormik } from "formik";
import { H4, H5, Tiny } from "components/Typography";
import AppTextField from "components/input-fields/AppTextField";
import { KeyboardArrowDown } from "@mui/icons-material";
import * as Yup from "yup"; // styled components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import FlexBetween from "components/flexbox/FlexBetween";
import { useNavigate } from "react-router-dom";
import KelasList from "./latihan-daftar-peserta-list";
import { classStore } from "store/class/classStore";
import useAuth from "hooks/useAuth";
import DatePickerRange from "components/input-fields/DateRange";
import { latihanStore } from "store/latihan/latihanStore";
import { latihanDaftarPesertaStore } from "store/latihanDaftarPeserta/latihanDaftarPesertaStore";
import LatihanDaftarPesertaList from "./latihan-daftar-peserta-list";

const LatihanDaftarPeserta = () => {
  const {
    fetchLatihanDaftarPeserta,
    loading,
    latihanDaftarPesertaData,
    downloadClassData,
  } = latihanDaftarPesertaStore();
  const { fetchLatihanData, latihanData } = latihanStore();
  const { accessToken } = useAuth();

  const [selectedDateRangeState, setSelectedDateRangeState] = useState([
    null,
    null,
  ]);

  useEffect(() => {
    let payload = {
      status: "active",
    };
    fetchLatihanData(payload, accessToken);
  }, [accessToken, fetchLatihanData]);

  const handleDateRangeChange = (dates) => {
    setSelectedDateRangeState(dates);
    setValues({
      ...values,
      date_from: dates && dates[0] ? dates[0].startOf("day").toDate() : "",
      date_to: dates && dates[1] ? dates[1].startOf("day").toDate() : "",
    });
  };

  const navigate = useNavigate();

  const initialValues = {
    form_code: "",
    name: "",
    team_name: "",
    class_name: "",
    status: "",
    date_from: "",
    date_to: "",
  };

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      fetchLatihanDaftarPeserta(payload, accessToken);
    },
  });

  let payload = {
    form_code: values.form_code,
    name: values.name,
    team_name: values.team_name,
    class_name: values.class_name,
    status: values.status,
    date_from: values.date_from,
    date_to: values.date_to,
  };

  useEffect(() => {
    fetchLatihanDaftarPeserta(payload, accessToken);
  }, []);

  const handleDownload = () => {
    downloadClassData(payload, accessToken);
  };

  const statusOptions = [
    { value: "active", label: "Aktif" },
    { value: "inactive", label: "Tidak Aktif" },
  ];

  const bracketOptions = [
    { value: "402", label: "402" },
    { value: "201", label: "201" },
  ];

  return (
    <Card
      sx={{
        mt: 3,
      }}
    >
      <H5 padding={3}>Data Latihan Peserta</H5>
      <Divider />

      <form onSubmit={handleSubmit}>
        <Box margin={3}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <AppTextField
                fullWidth
                name="form_code"
                id="form_code"
                label="No Token"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.form_code}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <AppTextField
                fullWidth
                name="name"
                id="name"
                label="Nama Hari Libur"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <AppTextField
                fullWidth
                name="team_name"
                id="team_name"
                label="Nama Hari Libur"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.team_name}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <DatePickerRange
                selectedDateRange={selectedDateRangeState}
                handleDateChange={handleDateRangeChange}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <AppTextField
                select
                fullWidth
                label="Jenis Kendaraan / Kelas Latihan"
                variant="outlined"
                placeholder="Pilih Jenis Kendaraan / Jenis Latihan"
                name="training_class_id"
                value={values?.training_class_id || ""}
                onChange={(event) => {
                  const selectedOption = latihanData.find(
                    (option) => option.id === event.target.value
                  );
                  setFieldValue("training_class_id", event.target.value);
                  setFieldValue("price", selectedOption?.price || null); // Set the price from the selected option
                }}
                onBlur={handleBlur}
                SelectProps={{
                  native: true,
                  IconComponent: KeyboardArrowDown,
                }}
                helperText={
                  touched?.training_class_id && errors?.training_class_id
                }
                error={Boolean(
                  touched?.training_class_id && errors?.training_class_id
                )}
              >
                <option value="" disabled></option>
                {latihanData?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </AppTextField>
            </Grid>

            <Grid item xs={12}>
              <FlexBetween>
                <Box>
                  {loading ? (
                    <LoadingButton loading variant="contained">
                      Search
                    </LoadingButton>
                  ) : (
                    <Button type="submit" variant="contained">
                      Search
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={handleDownload}
                    sx={{
                      ml: 2,
                    }}
                  >
                    Download
                  </Button>
                </Box>
              </FlexBetween>
            </Grid>
          </Grid>
        </Box>
      </form>
      <Divider />
      <Grid item xs={12}>
        <LatihanDaftarPesertaList />
      </Grid>
    </Card>
  );
};

export default LatihanDaftarPeserta;
