import React, { useContext } from "react";

import { Delete, Edit, Visibility } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Stack,
  styled,
  Table,
  TableContainer,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import AppCheckBox from "components/AppCheckBox";
import Scrollbar from "components/ScrollBar";
import { H5, Tiny } from "components/Typography";
import { useState } from "react";
import TableHeader from "page-sections/dashboards/CRM/customerList/TableHeader";
import { championshipsStore } from "store/championships/championshipStore";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
// import { AuthContext } from "contexts/JWTAuth";
import { classStore } from "store/class/classStore";
// import KelasDelete from "./kelas-delete";
// import KelasMassDelete from "./kelas-mass-delete";
import FlexBetween from "components/flexbox/FlexBetween";
import useAuth from "hooks/useAuth";
import { startListStore } from "store/startList/startListStore";
// import KejuaraanDelete from "./kejuaraan-delete";
// import KejuaraanMassDelete from "./kejuaraan-mass-delete";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) return -1;
  if (b[orderBy] > a[orderBy]) return 1;
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if (!stabilizedThis) {
    return [];
  }
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const tableHeading = [
  {
    id: "token",
    label: "No Token",
    alignRight: false,
  },
  {
    id: "star_number",
    label: "No. Start",
    alignRight: false,
  },
  {
    id: "team.name",
    label: "Tim",
    alignRight: false,
  },
  {
    id: "price_class.bracket",
    label: "Bracket",
    alignRight: false,
  },
  {
    id: "price_class.championship_id",
    label: "ID Kejuaraan",
    alignRight: false,
  },
  {
    id: "price_class.name",
    label: "Kelas",
    alignRight: false,
  },
  {
    id: "vehicle.type",
    label: "Tipe Kendaraan",
    alignRight: false,
  },
  {
    id: "vehicle.merk",
    label: "Merk Kendaraan",
    alignRight: false,
  },
  //   {
  //     id: "action",
  //     label: "Action",
  //     alignRight: false,
  //   },
];

const StyledTableCell = styled(TableCell)(() => ({
  paddingTop: 10,
  paddingBottom: 10,
}));

const ListStartTable = () => {
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [order, setOrder] = useState("asc");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { accessToken } = useAuth();
  const { startListData, loading } = startListStore();
  const navigate = useNavigate();

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (checked) => {
    if (checked) {
      const newSelecteds = startListData.map((n) => n.token);
      setSelected(newSelecteds);
      return;
    }

    setSelected([]);
  };

  const handleDeleteSelected = () => {
    if (selected.length > 0) {
      setOpenModal(true);
    }
  };

  const handleClick = (token) => {
    const selectedIndex = selected.indexOf(token);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, token);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    setSelectedId(token);
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPageValue = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPageValue);
    setPage(0);
  };

  const filteredUsers = stableSort(
    startListData,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <H5 mb={2}>Daftar Peserta</H5>

      <Scrollbar>
        <TableContainer
          sx={{
            minWidth: 800,
          }}
        >
          <Table>
            <TableHeader
              order={order}
              orderBy={orderBy}
              heading={tableHeading}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              rowCount={startListData?.length}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {loading ? (
                <StyledTableCell colSpan={6} align="center">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100px",
                    }}
                  >
                    <BeatLoader color="#2499EF" />
                  </div>
                </StyledTableCell>
              ) : (
                filteredUsers?.map((row, index) => {
                  const { token, star_number, team, price_class, vehicle } =
                    row;
                  const isItemSelected = selected.indexOf(token) !== -1;
                  return (
                    <TableRow
                      key={index}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      <StyledTableCell padding="checkbox">
                        <AppCheckBox
                          checked={isItemSelected}
                          onClick={() => handleClick(token)}
                        />
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Tiny fontWeight={600} color="text.primary">
                            {token}
                          </Tiny>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Tiny fontWeight={600} color="text.primary">
                            {star_number}
                          </Tiny>
                        </Stack>
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {team.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {price_class.bracket}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {price_class.championship_id}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {price_class.name}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {vehicle.type}
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        {vehicle.merk}
                      </StyledTableCell>
                      {/* <StyledTableCell align="left">
                        <IconButton>
                          <Visibility
                            sx={{ color: "text.disabled" }}
                            onClick={() => {
                              navigate("/dashboard/list-start-detail", {
                                state: { detailKejuaraan: row },
                              });
                            }}
                          />
                        </IconButton>
                      </StyledTableCell> */}
                    </TableRow>
                  );
                })
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={startListData?.length}
        rowsPerPageOptions={[5, 10, 25]}
        onPageChange={(_, page) => setPage(page)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
};

export default ListStartTable;
