import duotone from "icons/duotone";
export const navigations = [
  {
    type: "label",
    label: "Kejuaraan",
  },
  {
    name: "Kejuaraan",
    path: "/kejuaraan",
    icon: duotone.PersonChalkboard,
    children: [
      {
        name: "Input Form",
        path: "/kejuaraan/input-form",
      },
      // {
      //   name: "Daftar Peserta",
      //   path: "/registration/kejuaraan/daftar-peserta-kejuaraan",
      // },
    ],
  },
  {
    type: "label",
    label: "Latihan",
  },
  {
    name: "Latihan",
    path: "/latihan",
    icon: duotone.Calender,
    children: [
      {
        name: "Input Form",
        path: "/latihan/input-form",
      },
    ],
  },
  {
    name: "Status Pendaftaran",
    path: "/status-pendaftaran",
    icon: duotone.Pages,
  },
];
