import {
  Box,
  Card,
  Checkbox,
  Stack,
  styled,
  Table,
  TableRow,
  useTheme,
  Grid,
  Button,
  TablePagination,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import AppPagination from "components/AppPagination";
import Scrollbar from "components/ScrollBar";
import BlankCheckBoxIcon from "icons/BlankCheckBoxIcon";
import CheckBoxIcon from "icons/CheckBoxIcon";
import SearchArea from "page-sections/admin-ecommerce/product-list/search-area";
import AppTextField from "components/input-fields/AppTextField";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table";
import useAuth from "hooks/useAuth";
import { verifikasiStore } from "store/verifikasi/verifikasiStore";
import { useFormik } from "formik";
import { H4, H5, Tiny } from "components/Typography";
import { KeyboardArrowDown } from "@mui/icons-material";
import * as Yup from "yup"; // styled components
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingButton } from "@mui/lab";
import FlexBetween from "components/flexbox/FlexBetween";
import { useNavigate } from "react-router-dom";
import { classStore } from "store/class/classStore";
import { BeatLoader } from "react-spinners";
import HeadingArea from "./latihan-heading-area";
import { latihanStore } from "store/latihan/latihanStore";
import { latihanVerifikasiStore } from "store/latihanVerifikasi/latihanVerifikasiStore";
import columnShape from "./columnShape";

const HeadTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 600,
  color: theme.palette.text.secondary,
  "&:first-of-type": {
    paddingLeft: 24,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));
const BodyTableCell = styled(HeadTableCell)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: 13,
  fontWeight: 500,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const SelectCheckBox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;
  useEffect(() => {
    if (resolvedRef) {
      resolvedRef.current.indeterminate = indeterminate;
    }
  }, [resolvedRef, indeterminate]);
  return (
    <Checkbox
      {...rest}
      disableRipple
      ref={resolvedRef}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
    />
  );
});

const LatihanVerifikasi = () => {
  const [value, setValue] = useState("semua");
  const [pagePagination, setPagePagination] = useState(0);
  const [orderBy, setOrderBy] = useState("name");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { accessToken } = useAuth();
  const [filteredData, setFilteredData] = useState([]);
  const { fetchLatihanData, latihanData } = latihanStore();
  const {
    fetchLatihanVerifikasiData,
    latihanVerifikasiData,
    latihanVerifikasiTotalData,
    loading,
    downloadVerifikasiData,
  } = latihanVerifikasiStore();

  useEffect(() => {
    let payload = {
      status: "active",
    };
    fetchLatihanData(payload, accessToken);
  }, [accessToken, fetchLatihanData]);

  const hasData = filteredData?.length > 0;

  const theme = useTheme();
  const columns = useMemo(() => columnShape, []);
  const tableData = useMemo(() => filteredData, [filteredData]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    gotoPage,
  } = useTable(
    {
      columns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <SelectCheckBox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => (
            <SelectCheckBox {...row.getToggleRowSelectedProps()} />
          ),
        },
        ...columns,
      ]);
    }
  );

  const initialValues = {
    form_code: "",
    name: "",
    team_name: "",
    id: "",
  };

  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues,
    onSubmit: (values) => {
      fetchLatihanVerifikasiData(payload, accessToken);
    },
  });

  let payload = {
    form_code: values.form_code,
    championship_name: values.championship_name,
    team_name: values.team_name,
    id: values.id,
    status: value,
    limit: latihanVerifikasiTotalData,
  };

  if (value === "semua") {
    payload = {
      form_code: values.form_code,
      championship_name: values.championship_name,
      team_name: values.team_name,
      id: values.id,
      status: "",
      limit: latihanVerifikasiTotalData,
    };
  } else {
    payload = {
      form_code: values.form_code,
      championship_name: values.championship_name,
      team_name: values.team_name,
      id: values.id,
      status: value,
      limit: latihanVerifikasiTotalData,
    };
  }

  useEffect(() => {
    fetchLatihanVerifikasiData(payload, accessToken);
  }, []);

  const navigate = useNavigate();

  const handleDownload = () => {
    downloadVerifikasiData(payload, accessToken);
  };

  const handleChangePagination = (_, currentPageNo) =>
    gotoPage(currentPageNo - 1);

  useEffect(() => {
    setFilteredData(latihanVerifikasiData);
  }, [latihanVerifikasiData]);

  const changeTab = (_, newValue) => {
    setValue(newValue);
    if (newValue === "semua") {
      setFilteredData(latihanVerifikasiData);
    } else {
      const filtered = latihanVerifikasiData.filter(
        (item) => item.status === newValue
      );
      setFilteredData(filtered);
    }
  };

  return (
    <Box pt={2} pb={4}>
      <Card
        sx={{
          py: 2,
        }}
      >
        <Box px={3}>
          <HeadingArea
            value={value}
            changeTab={changeTab}
            setValue={setValue}
          />
          <form onSubmit={handleSubmit}>
            <Box margin={3}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <AppTextField
                    fullWidth
                    name="form_code"
                    id="form_code"
                    label="Nomor Form"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.form_code}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <AppTextField
                    fullWidth
                    name="name"
                    id="name"
                    label="Nama Pembalap"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.name}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <AppTextField
                    fullWidth
                    name="team_name"
                    id="team_name"
                    label="Nama Tim"
                    variant="outlined"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.team_name}
                  />
                </Grid>

                <Grid item sm={6} xs={12}>
                  <AppTextField
                    select
                    fullWidth
                    label="Jenis Kendaraan / Kelas Latihan"
                    variant="outlined"
                    placeholder="Pilih Jenis Kendaraan / Jenis Latihan"
                    name="id"
                    value={values?.id || ""}
                    onChange={handleChange}
                    SelectProps={{
                      native: true,
                      IconComponent: KeyboardArrowDown,
                    }}
                  >
                    <option value="" disabled></option>
                    {latihanData?.map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </AppTextField>
                </Grid>

                <Grid item xs={12}>
                  <FlexBetween>
                    <Box>
                      {loading ? (
                        <LoadingButton loading variant="contained">
                          Search
                        </LoadingButton>
                      ) : (
                        <Button type="submit" variant="contained">
                          Search
                        </Button>
                      )}
                      <Button
                        variant="outlined"
                        onClick={handleDownload}
                        sx={{
                          ml: 2,
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                  </FlexBetween>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>

        <Scrollbar autoHide={false}>
          <Table
            {...getTableProps()}
            sx={{
              minWidth: 900,
            }}
          >
            <TableHead
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
              }}
            >
              {headerGroups.map((headerGroup, index) => (
                <TableRow key={index} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, index) => (
                    <HeadTableCell
                      key={index}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                    </HeadTableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>

            {loading ? (
              <TableBody>
                <TableRow>
                  <BodyTableCell colSpan={columns.length} align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100px",
                      }}
                    >
                      <BeatLoader color="#2499EF" />
                    </div>
                  </BodyTableCell>
                </TableRow>
              </TableBody>
            ) : hasData ? (
              <TableBody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <TableRow {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, index) => (
                        <BodyTableCell key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </BodyTableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <TableBody>
                <TableRow>
                  <BodyTableCell colSpan={columns.length}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      Tidak ada data
                    </div>
                  </BodyTableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </Scrollbar>

        <Stack alignItems="center" marginY={4}>
          <AppPagination
            shape="rounded"
            onChange={handleChangePagination}
            count={pageOptions.length}
          />
        </Stack>
      </Card>
    </Box>
  );
};

export default LatihanVerifikasi;
