import { create } from "zustand";
import { toast } from "react-toastify";

export const toastStore = create((set) => ({
  success: (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  },
  error: (message) => {
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  },
}));
