import { SvgIcon } from "@mui/material";

const Dashboard = props => {
  return <SvgIcon viewBox="0 0 18 18" {...props}>
      <path d="M6.50666 9.99332H1.27999C1.18193 9.99335 1.08483 9.97405 0.994225 9.93654C0.903624 9.89903 0.821301 9.84403 0.751962 9.77469C0.682623 9.70535 0.627624 9.62303 0.59011 9.53242C0.552595 9.44182 0.5333 9.34472 0.533325 9.24666V1.77999C0.5333 1.68193 0.552595 1.58483 0.59011 1.49422C0.627624 1.40362 0.682623 1.3213 0.751962 1.25196C0.821301 1.18262 0.903624 1.12762 0.994225 1.09011C1.08483 1.0526 1.18193 1.0333 1.27999 1.03333H6.50666C6.60472 1.0333 6.70182 1.0526 6.79243 1.09011C6.88303 1.12762 6.96535 1.18262 7.03469 1.25196C7.10403 1.3213 7.15903 1.40362 7.19654 1.49422C7.23406 1.58483 7.25335 1.68193 7.25333 1.77999V9.24666C7.25335 9.34472 7.23406 9.44182 7.19654 9.53242C7.15903 9.62303 7.10403 9.70535 7.03469 9.77469C6.96535 9.84403 6.88303 9.89903 6.79243 9.93654C6.70182 9.97405 6.60472 9.99335 6.50666 9.99332Z" />
      <path d="M6.50666 15.9667H1.27999C1.18193 15.9667 1.08483 15.9474 0.994225 15.9099C0.903624 15.8724 0.821301 15.8174 0.751962 15.7481C0.682623 15.6787 0.627624 15.5964 0.59011 15.5058C0.552595 15.4152 0.5333 15.3181 0.533325 15.22V12.2334C0.5333 12.1353 0.552595 12.0382 0.59011 11.9476C0.627624 11.857 0.682623 11.7747 0.751962 11.7053C0.821301 11.636 0.903624 11.581 0.994225 11.5435C1.08483 11.506 1.18193 11.4867 1.27999 11.4867H6.50666C6.60472 11.4867 6.70182 11.506 6.79243 11.5435C6.88303 11.581 6.96535 11.636 7.03469 11.7053C7.10403 11.7747 7.15903 11.857 7.19654 11.9476C7.23406 12.0382 7.25335 12.1353 7.25333 12.2334V15.22C7.25335 15.3181 7.23406 15.4152 7.19654 15.5058C7.15903 15.5964 7.10403 15.6787 7.03469 15.7481C6.96535 15.8174 6.88303 15.8724 6.79243 15.9099C6.70182 15.9474 6.60472 15.9667 6.50666 15.9667Z" />
      <path className="secondary" d="M14.72 15.9667H9.49334C9.39528 15.9667 9.29817 15.9474 9.20757 15.9099C9.11697 15.8724 9.03465 15.8174 8.96531 15.748C8.89597 15.6787 8.84097 15.5964 8.80346 15.5058C8.76594 15.4152 8.74665 15.3181 8.74667 15.22V9.24667C8.74665 9.14861 8.76594 9.0515 8.80346 8.9609C8.84097 8.8703 8.89597 8.78798 8.96531 8.71864C9.03465 8.6493 9.11697 8.5943 9.20757 8.55678C9.29817 8.51927 9.39528 8.49997 9.49334 8.5H14.72C14.8181 8.49997 14.9152 8.51927 15.0058 8.55678C15.0964 8.5943 15.1787 8.6493 15.248 8.71864C15.3174 8.78798 15.3724 8.8703 15.4099 8.9609C15.4474 9.0515 15.4667 9.14861 15.4667 9.24667V15.22C15.4667 15.3181 15.4474 15.4152 15.4099 15.5058C15.3724 15.5964 15.3174 15.6787 15.248 15.748C15.1787 15.8174 15.0964 15.8724 15.0058 15.9099C14.9152 15.9474 14.8181 15.9667 14.72 15.9667Z" />
      <path className="secondary" d="M14.72 7.00666H9.49331C9.39525 7.00668 9.29814 6.98739 9.20754 6.94987C9.11694 6.91236 9.03462 6.85736 8.96528 6.78802C8.89594 6.71868 8.84094 6.63636 8.80343 6.54576C8.76591 6.45516 8.74662 6.35805 8.74664 6.25999V1.77999C8.74662 1.68193 8.76591 1.58483 8.80343 1.49422C8.84094 1.40362 8.89594 1.3213 8.96528 1.25196C9.03462 1.18262 9.11694 1.12762 9.20754 1.09011C9.29814 1.0526 9.39525 1.0333 9.49331 1.03333H14.72C14.818 1.0333 14.9151 1.0526 15.0057 1.09011C15.0963 1.12762 15.1787 1.18262 15.248 1.25196C15.3173 1.3213 15.3723 1.40362 15.4099 1.49422C15.4474 1.58483 15.4667 1.68193 15.4666 1.77999V6.25999C15.4667 6.35805 15.4474 6.45516 15.4099 6.54576C15.3723 6.63636 15.3173 6.71868 15.248 6.78802C15.1787 6.85736 15.0963 6.91236 15.0057 6.94987C14.9151 6.98739 14.818 7.00668 14.72 7.00666V7.00666Z" />
    </SvgIcon>;
};

export default Dashboard;