import {
  BrowserRouter,
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { createCustomTheme } from "./theme";
import Routes from "./routes";
import "./i18n";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router } from "react-router-dom";
import { JWTAuthProvider } from "contexts/JWTAuth";
import ErrorFallback from "pages/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";

const App = () => {
  const router = createBrowserRouter(Routes());
  const { settings } = useSettings();
  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
  });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RTL>
          <CssBaseline />
          <ToastContainer />
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <RouterProvider router={router} />
          </ErrorBoundary>
        </RTL>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
