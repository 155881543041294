import { BASE_URL_ENDPOINT } from "constant/constanta";
import { create } from "zustand";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const latihanDaftarPesertaStore = create((set) => ({
  latihanDaftarPesertaData: [],
  latihanDaftarPesertaTotalData: [],
  latihanDaftarPesertaDetailData: [],
  loading: false,
  error: null,

  fetchLatihanDaftarPeserta: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(
        `${BASE_URL_ENDPOINT}/api/training/participants`,
        {
          params: { ...payload },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const data = response.data.data;
      const total_data = response.data.total_data;
      set({
        latihanDaftarPesertaData: data,
        latihanDaftarPesertaTotalData: total_data,
        loading: false,
      });
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  sendToken: async (id, authToken, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/training/send-token/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
        navigate("/dashboard/verifikasi");
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  downloadReceipts: async (id, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(
        `${BASE_URL_ENDPOINT}/api/training/download-receipts/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          responseType: "blob",
        }
      );
      const fileName = response.headers
        .get("content-disposition")
        ?.split("filename=")[1]
        .replace(/"/g, "");
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      set({ loading: false });
    } catch (error) {
      set({ error, loading: false });
      if (error.message) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  fetchLatihanDaftarPesertaDetailData: async (id, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(
        `${BASE_URL_ENDPOINT}/api/training/detail/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const data = response.data.data;
      set({ latihanVerifikasiDetailData: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  createLatihan: async (payload, authToken, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/training-class/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ championshipsData: data, loading: false });
        navigate("/dashboard/latihan");
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  editLatihanData: async (id, payload, authToken, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/training-class/update/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ latihanData: data, loading: false });
        navigate("/dashboard/latihan");
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  deleteLatihanData: async (id, authToken, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/training-class/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          latihanData: state.championshipsData.filter(
            (championship) => championship.id !== id
          ),
          loading: false,
        }));
        navigate("/dashboard/latihan");
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  massDeleteLatihanData: async (ids, authToken, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/training-class/mass-delete`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          data: {
            championship_ids: ids,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          latihanData: state.championshipsData.filter(
            (championship) => !ids.includes(championship.id)
          ),
          loading: false,
        }));
        navigate("/dashboard/latihan");
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  downloadVerifikasiData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/training/verifications`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          responseType: "blob",
        }
      );
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "Data Verifikasi Latihan.xlsx"; // Default file name
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="([^"]+)"/);
        if (match) {
          fileName = match[1];
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
      set({ loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },

  massInactiveChampionshipData: async (ids, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/championship/set-mass-inactive`,
        {
          class_ids: ids,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ championshipsData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },
}));
