import { TabContext, TabList } from "@mui/lab";
import { Button, styled, Tab } from "@mui/material";
import { Box } from "@mui/system";
import FlexBox from "components/flexbox/FlexBox";
import { H5 } from "components/Typography";
import { useTranslation } from "react-i18next"; // styled components

const Wrapper = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  justifyContent: "space-between",
}));
const TabListWrapper = styled(TabList)(({ theme }) => ({
  [theme.breakpoints.down(727)]: {
    order: 3,
  },
})); // --------------------------------------------------------------------

// --------------------------------------------------------------------
const HeadingArea = ({ value, changeTab, setValue }) => {
  const { t } = useTranslation();
  return (
    <Wrapper gap={1}>
      <FlexBox alignItems="center">
        <H5>{t("Verifikasi")}</H5>
      </FlexBox>

      <TabContext value={value}>
        <TabListWrapper variant="scrollable" onChange={changeTab}>
          <Tab disableRipple label={t("Semua")} value="semua" />
          <Tab disableRipple label={t("Menunggu Pembayaran")} value="request" />
          <Tab disableRipple label={t("Ditolak")} value="rejected" />
          <Tab disableRipple label={t("Disetujui")} value="approved" />
        </TabListWrapper>
      </TabContext>
    </Wrapper>
  );
};

export default HeadingArea;
