import { BASE_URL_ENDPOINT } from "constant/constanta";
import { create } from "zustand";
import axios from "axios";
import { toastStore } from "store/toast/toastStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const adminStore = create((set) => ({
  adminData: [],
  adminDetailData: [],
  loading: false,
  error: null,

  fetchAdminData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(`${BASE_URL_ENDPOINT}/api/admins`, {
        params: { ...payload },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = response.data.data;
      set({ adminData: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  fetchAdminDetailData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.get(`${BASE_URL_ENDPOINT}/api/admins`, {
        params: { ...payload },
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const data = response.data.data;
      set({ adminDetailData: data, loading: false });
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  createAdmin: async (payload, authToken, navigate) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/admin/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 201) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ adminData: data, loading: false });
        navigate("/management/user-management");
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  resetPassword: async (id, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/admin/reset-password/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code !== 201) {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ loading: false });
      } else if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ adminData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  editAdminData: async (id, payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/admin/update/${id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ adminData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  massInactiveClassData: async (ids, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.put(
        `${BASE_URL_ENDPOINT}/api/class/set-mass-inactive`,
        {
          class_ids: ids,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        const data = response.data.data;
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set({ classData: data, loading: false });
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  deleteAdminData: async (id, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/admin/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          adminData: state.adminData.filter((dataClass) => dataClass.id !== id),
          loading: false,
        }));
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  massDeleteClassData: async (ids, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.delete(
        `${BASE_URL_ENDPOINT}/api/class/mass-delete`,
        {
          data: {
            class_ids: ids,
          },
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      if (response.data.code === 200) {
        toast.success(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        set((state) => ({
          classData: state.classData.filter(
            (dataClass) => !ids.includes(dataClass.id)
          ),
          loading: false,
        }));
      } else {
        toast.error(response?.data?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      set({ error, loading: false });
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      } else {
        toast.error("Error", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    }
  },

  downloadClassData: async (payload, authToken) => {
    try {
      set({ loading: true });
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/api/class/download`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
          responseType: "blob",
        }
      );
      const contentDisposition = response.headers.get("content-disposition");
      let fileName = "Data Kelas.xlsx"; // Default file name
      if (contentDisposition) {
        const match = contentDisposition.match(/filename="([^"]+)"/);
        if (match) {
          fileName = match[1];
        }
      }
      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = fileName;
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(url);
      set({ loading: false });
    } catch (error) {
      set({ error, loading: false });
    }
  },
}));
