import { createContext, useEffect, useReducer } from "react";
import LoadingScreen from "components/LoadingScreen";
import axios from "../utils/axios"; // --------------------------------------------------------
import { BASE_URL_ENDPOINT } from "constant/constanta";
import jwtDecode from "jwt-decode";
import { toast } from "react-toastify";

// --------------------------------------------------------
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  passwordDefault: false,
};
const isValidToken = (accessToken) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};
const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem("accessToken");
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
        accessToken: action.payload.accessToken,
        permissions: action.payload.permissions,
        passwordDefault: action.payload.passwordDefault,
      };
    }

    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        passwordDefault: action.payload.passwordDefault,
        permissions: action.payload.permissions,
      };
    }

    case "LOGOUT": {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
        passwordDefault: false,
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  accessToken: null,
  logout: () => {},
  login: (email, password) => Promise.resolve(),
});
export const JWTAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const { data } = await axios.post(`${BASE_URL_ENDPOINT}/api/auth/login`, {
      email,
      password,
    });
    const accessToken = data?.data?.token;
    setSession(accessToken);

    dispatch({
      type: "LOGIN",
      payload: {
        user: data?.data?.user,
        passwordDefault: data?.data?.user?.is_password_default,
        permissions: data?.data?.user?.permissions,
        accessToken,
      },
    });
  };

  const logout = () => {
    setSession(null);
    dispatch({
      type: "LOGOUT",
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem("accessToken");
        const { data } = await axios.get(`${BASE_URL_ENDPOINT}/api/user`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (data?.data?.name) {
          dispatch({
            type: "INIT",
            payload: {
              user: data.data,
              isAuthenticated: true,
              accessToken: accessToken,
              permissions: data.data.permissions,
            },
          });
        } else {
          dispatch({
            type: "INIT",
            payload: {
              user: null,
              isAuthenticated: false,
              accessToken: null,
              permissions: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: "INIT",
          payload: {
            user: null,
            isAuthenticated: false,
            accessToken: null,
            permissions: null,
          },
        });
      }
    })();
  }, []);

  const { accessToken, permissions, passwordDefault } = state;

  if (!state.isInitialized) return <LoadingScreen />;
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        accessToken,
        permissions,
        logout,
        passwordDefault,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
